import React, { useEffect, useState, useRef } from 'react';

import Modal from '../Modal';
import constants from '../../../constants';

import { InitializeTestsBody, InitializeTestsActions } from './InitializeTests';
import { TestsInProgressBody, TestsInProgressActions } from './TestsInProgress';
import { SuccessScreenBody, SuccessScreenActions } from './SuccessScreen';
import { ErrorsScreenBody, ErrorsScreenActions } from './ErrorsScreen';

import HeaderImage from '../../../images/template-test.png';

export const Header = () => (
  <div className="d-flex align-items-center ">
    <img src={HeaderImage} alt="template test header logo" />
    <h3 className="title cw-heading--saascampaign send-test-email__title ml-3">
      Email test wizard
    </h3>
  </div>
);

const TemplateTestsWizard = ({
  open,
  setOpen,
  currentState,
  setCurrentState,
  runTests,
  runTestRef,
  runTestCount,
  validationError,
  setValidationError,
  setShowValidationAnimation
}) => {
  const [comps, setComps] = useState({
    Body: <InitializeTestsBody />,
    Actions: <InitializeTestsActions />
  });
  //const [errorResponse, setErrorResponse] = useState({ data: null });
  const [showWarning, setShowWarning] = useState(false);
  const timerId = useRef(null);

  const startTests = async () => {
    setCurrentState(constants.EMAIL_GUIDELINES_STATUS.IN_PROGRESS);
    const response = await runTests();
    const errorsExist = Object.values(response.data).find(
      collection => collection.length > 0
    );
    if (errorsExist) {
      setValidationError(response.data);
      setCurrentState(constants.EMAIL_GUIDELINES_STATUS.HAS_ERRORS);
    } else {
      setCurrentState(constants.EMAIL_GUIDELINES_STATUS.SUCCESS);
    }
  };
  const closeModal = () => {
    setOpen(false);
    //setCurrentState(constants.EMAIL_GUIDELINES_STATUS.INITIALIZE);
  };

  // show error found splash for few sec
  useEffect(() => {
    if (showWarning) {
      timerId.current = setTimeout(() => {
        setShowWarning(false);
      }, 5000);
    }
    return () => {
      clearTimeout(timerId.current);
    };
  }, [showWarning]);

  useEffect(() => {
    if (runTestRef) {
      runTestRef.current = runBackGroundTests;
    }
  }, []);

  useEffect(() => {
    if (open) {
      setShowValidationAnimation(false);
    }
  }, [open]);

  useEffect(() => {
    switch (currentState) {
      case constants.EMAIL_GUIDELINES_STATUS.INITIALIZE:
        setComps({
          Body: <InitializeTestsBody />,
          Actions: (
            <InitializeTestsActions
              closeModal={closeModal}
              onNext={startTests}
            />
          )
        });
        break;

      case constants.EMAIL_GUIDELINES_STATUS.IN_PROGRESS:
        setComps({
          Body: <TestsInProgressBody />,
          Actions: <TestsInProgressActions closeModal={closeModal} />
        });
        break;

      case constants.EMAIL_GUIDELINES_STATUS.SUCCESS:
        setComps({
          Body: <SuccessScreenBody />,
          Actions: <SuccessScreenActions closeModal={closeModal} />
        });
        break;

      case constants.EMAIL_GUIDELINES_STATUS.HAS_ERRORS:
        setComps({
          Body: <ErrorsScreenBody errorList={validationError} />,
          Actions: (
            <ErrorsScreenActions
              closeModal={closeModal}
              reviewTestCriteria={() =>
                setCurrentState(constants.EMAIL_GUIDELINES_STATUS.INITIALIZE)
              }
            />
          )
        });
        break;

      default:
        setComps({
          Body: <InitializeTestsBody />,
          Actions: (
            <InitializeTestsActions
              closeModal={closeModal}
              onNext={startTests}
            />
          )
        });
        break;
    }
  }, [currentState]);

  const { Body, Actions } = comps;

  const runBackGroundTests = async () => {
    setCurrentState(constants.EMAIL_GUIDELINES_STATUS.IN_PROGRESS);
    const response = await runTests();
    const list = Object.values(response.data);
    const errorsExist = list.find(collection => collection.length > 0);
    //validationType: 'abTestDuplicate',

    const hasWarningOnly = list
      .find(collection => collection.length === 1)
      ?.find(ele => ele.validationType === 'abTestDuplicate');
    if (hasWarningOnly) {
      runTestCount.current += 1;
    }
    if (errorsExist) {
      if (runTestCount.current <= 1) {
        setValidationError(response.data);
        setCurrentState(constants.EMAIL_GUIDELINES_STATUS.HAS_ERRORS);
        setOpen(true);
        return true;
      } else {
        return false;
      }
    } else {
      setCurrentState(constants.EMAIL_GUIDELINES_STATUS.SUCCESS);
      return false;
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      size="tiny"
      header={<Header />}
      actions={Actions}
      classes={`template-tests-modal ${currentState}`}
      closeModal={closeModal}
    >
      {Body}
    </Modal>
  );
};

export default TemplateTestsWizard;
