import React, { useState, useEffect } from 'react';
import ActionEditIcon from '../../../images/action-edit.png';
import ActionDesktopPreviewIcon from '../../../images/action-desktop-preview.png';
import ActionSaveAsTemplateIcon from '../../../images/action-save-as-template.png';
import ActionSendTestEmailIcon from '../../../images/action-send-test-email.png';
import ActionExportIcon from '../../../images/action-export.png';
import ActionSaveIcon from '../../../images/email-save.png';
import ActionEmailChecklistIcon from '../../../images/email-checklist.png';
import TemplateTestIcon from '../../../images/template-test-button.png';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import { checkIsEmailAdminOrACS } from 'selectors';
import EmailDesignChecklistModal from 'components/molecules/EmailDesignChecklistModal/EmailDesignChecklistModal';
import DownloadIcon from '../../../images/download-image.svg';
import TemplateTestsWizard from '../RunTestsWizard';
import Loader from 'components/Loader';
import constants from '../../../constants';
import classNames from 'classnames';

export default function ActionBar({
  onMobilePreview,
  onDesktopPreview,
  sentTestEmailOnClick,
  onExport,
  onClickEdit,
  isExportButton = false,
  isSaveButton = false,
  isPreviewMobile = true,
  hideDesignCheckListBtn = false,
  isDownloadBtn = false,
  isRunTests,
  onRunTests,
  templateTestStatus,
  showTemplateTest,
  setTemplateTestStatus,
  shouldOpenValidationDialog,
  setOpenValidationDialog,
  resetValidationDialog,
  isLoading,
  runTestRef,
  runTestCount,
  ...props
}) {
  const { authorizedUserData } = useSelector(state => state.authorized);
  const isEmailAdminOrACS = checkIsEmailAdminOrACS(authorizedUserData);
  const [isOpen, setIsOpen] = useState(false);
  const isSaveAsTemplateAllowed =
    isEmailAdminOrACS ||
    ['requestor', 'approver'].includes(authorizedUserData.group);
  const onClose = () => {
    setIsOpen(false);
  };
  // /** after save open dialog */
  // useEffect(() => {
  //   if (shouldOpenValidationDialog) {
  //     setIsRunTestsModalOpen(true);
  //   }
  // }, [shouldOpenValidationDialog]);

  return (
    <div className="action-bar">
      {isLoading && <Loader />}
      <div className="actions-left">
        {/* {isPreviewMobile ? (
          <Action
            icon={ActionMobilePreviewIcon}
            title="Preview"
            id="mobile-preview"
            onClick={onMobilePreview}
          />
        ) : null} */}
        <Action
          icon={ActionDesktopPreviewIcon}
          title="Preview"
          id="mobile-preview"
          onClick={onDesktopPreview}
        />
        {showTemplateTest && (
          <Action
            icon={TemplateTestIcon}
            onClick={() => {
              props.setShowValidationAnimation(true);
              props.validationClickHandler()
            }}
            title="Run tests"
            id="run-tests"
          />
        )}
        <Action
          icon={ActionSendTestEmailIcon}
          onClick={sentTestEmailOnClick}
          title="Send test email"
          id="send-test-email"
        />
        {isDownloadBtn ? (
          <Action
            icon={DownloadIcon}
            title="Download as image"
            id="save-email"
            onClick={props.downloadTemplateImg}
          />
        ) : null}
      </div>
      <div className="actions-right">
        {!hideDesignCheckListBtn ? (
          <Action
            icon={ActionEmailChecklistIcon}
            title="Design checklist"
            id="design-checklist"
            onClick={() => setIsOpen(true)}
          />
        ) : (
          ''
        )}
        {isSaveButton ? (
          <Action
            icon={ActionSaveIcon}
            title="Save"
            id="save-email"
            onClick={props.onSaveClick}
          />
        ) : null}
        {onClickEdit ? (
          <Action
            icon={ActionEditIcon}
            title="Edit email"
            id="edit-email"
            onClick={onClickEdit}
          />
        ) : null}
        {isSaveAsTemplateAllowed ? (
          <Action
            icon={ActionSaveAsTemplateIcon}
            title="Save as template"
            id="save-as-template"
            onClick={props.onSaveAsClick}
          />
        ) : null}
        {isExportButton && isEmailAdminOrACS ? (
          <Action
            icon={ActionExportIcon}
            title="Export"
            id="export-email"
            onClick={onExport}
          />
        ) : null}
      </div>
      <EmailDesignChecklistModal isOpen={isOpen} onClose={onClose} />
      <TemplateTestsWizard
        open={shouldOpenValidationDialog}
        setOpen={setOpenValidationDialog}
        currentState={templateTestStatus}
        setCurrentState={setTemplateTestStatus}
        runTests={onRunTests}
        runTestRef={runTestRef}
        runTestCount={runTestCount}
        validationError={props.validationError}
        setValidationError={props.setValidationError}
        setShowValidationAnimation = {props.setShowValidationAnimation}
      />
    </div>
  );
}

function Action({ id, icon, onClick, title, className }) {
  return (
    <>
      <ReactTooltip
        id={id}
        place="bottom"
        type="info"
        multiline={true}
        className="cw-tooltip cw-email-tooltip saas_new_master_tooltip"
      />
      <button
        onClick={onClick}
        className={classNames('action-bar__action', className)}
        data-tip={title}
        data-for={id}
      >
        <img src={icon} alt={title} />
      </button>
    </>
  );
}
