import React, { useMemo, useRef } from 'react';
import RichTextEditor from '../../richTextEditor';
import ReactTooltip from 'react-tooltip';
import ErrorIcon from '../../atoms/ErrorIcon/ErrorIcon';

export default function ParagraphField({
  elem,
  errors,
  index,
  profileRichText,
  richTextData,
  isValid,
  removeAccordion,
  handleAccordionExpandCollapse,
  expand
}) {
  const hasRun = useRef(false);
  const paragraphText = useMemo(() => {
    const d = document.createElement('div');
    d.innerHTML = elem.paragraph;
    const textContent = d.textContent || d.innerText;
    return textContent;
  }, [elem.paragraph]);

  return (
    <div
      id={`accordion_${elem.dataType}`}
      className="accordion saas-form-field-accordion field"
      key={`${elem.dataType}_${index}`}
    >
      <ReactTooltip
        place="right"
        type="info"
        multiline={true}
        className="cw-tooltip"
      />
      <ErrorIcon isValid={isValid} />
      <div className="card" style={{ border: 0, overflow: 'visible' }}>
        <div className="card-header">
          <span
            className="saas-accordion-heading cw-color--primary"
            style={{ fontWeight: '700' }}
          >
            {paragraphText
              ? paragraphText.length < 70
                ? paragraphText
                : paragraphText.substring(0, 70) + '...'
              : elem.label}
          </span>
          <span
            type="button"
            className={`saas-accordion-arrow ${index}`}
            onClick={() => handleAccordionExpandCollapse(index)}
          >
            <i
              className={
                expand === index
                  ? 'fas fa-chevron-down transformIcon-180'
                  : 'fas fa-chevron-down'
              }
            ></i>
          </span>
          <span
            id={`close_${elem.dataType}`}
            className={'saas-accordion-close'}
            onClick={() => {
              removeAccordion(index);
            }}
          ></span>
        </div>
        <div className={expand === index ? 'collapse show' : 'collapse'}>
          <ReactTooltip
            place="right"
            type="info"
            multiline={true}
            className="cw-tooltip"
          />
          <div
            className="form-group form-group-field-width"
            key={`${elem.dataType}_${index}`}
          >
            <label htmlFor={`paragraph_${elem.id}`}>
              <span>Paragraph Text</span>
              <span
                className="float-right  cw-icon cw-icon--help"
                data-tip="Use this field to specify custom paragraph text to appear between the fields on the form"
                currentitem="false"
              >
                <i className="fas fa-question-circle"></i>
              </span>
            </label>
            <div
              id={`paragraph_${elem.id}`}
              className={errors[`paragraph_${elem.id}`] ? 'cw-error-focus' : ''}
            >
              <RichTextEditor
                defaultValue={
                  profileRichText &&
                  profileRichText[index] &&
                  profileRichText[index].paragraph
                    ? profileRichText[index].paragraph
                    : ''
                }
                parentCallback={textData => {
                  if (hasRun.current === false) {
                    hasRun.current = true;
                  } else {
                    richTextData(textData, index, elem);
                  }
                }}
                id={`paragraph__RTE_${elem.id}`}
              />
            </div>
            <span
              id={`paragraph_error_${elem.dataType}_${index}`}
              className={`cw-error ${
                errors[`paragraph_${elem.id}`] ? '' : 'cw-hidden'
              }`}
              data-control="name"
            >
              <i className="fas fa-exclamation-triangle mr-10"></i>
              Please enter the paragraph statement
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
