import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader, { LoaderWithText } from '../../components/Loader';
import BeeEmailEditor from 'components/organisms/BeeEmailEditor';
import SaveRowModel from './saveRowModel';
import SaveDisplayConditions from './saveDisplayConditions';
import GenerateImagePathModal from './GenerateImagePathModal';
import GenerateFilePathModal from './GenerateFilePathModal';
import ABTestingVariantValidation from './abTestingVariantValidation';
import CustomBrandRowsModel from './customBrandRowsModel';
import { getEmailBriefing } from '../../actions/emailbriefing/emailDesign';
import { openSaveEmailTemplate } from 'actions/emailbriefing/saveTemplate';
import { getBrandCountries, getBrands } from 'actions/dashboard';
import {
  getEmailTemplateEditByUid,
  saveEmailTemplate,
  saveEmailTemplateAndDownload
} from 'services/emailTemplateEdit';
import {
  saveEmailRowBlock,
  updateEmailBriefing,
  updateEmailRowBlock,
  getEmailRowBlockById,
  getBrandDetails
} from 'services/emailBriefings';
import Modal from 'react-modal';
import EmailTest from 'components/EmailTest';
import { isEmpty, get, set, omit } from 'lodash';
import { UPDATE_TEMPLATE_OBJECT } from 'actionTypes';
import { fetchBrandDefaults } from 'actions/brandDefaultsActions';
import { deleteEmailRow } from 'actions/emailbriefing/emailCoreRaws';
import { updateEmailCampaignDetails } from 'actions/emailbriefing/emailCampaignDetails';
import {
  checkIsEmailAdminOrACS,
  addMetaLangToTemplate,
  getRowAndVariantComboInfo,
  checkIfUserIsRequestorOrApprover,
  addWatermarkInTemplateImage,
  getBlobFromBuffer,
  imageURLComponents
} from 'selectors';
import constants from '../../constants';
import DeleteRowModal from 'pages/EmailCollection/deleteRowModal';
import _ from 'lodash';
import fileDownload from 'js-file-download';
import moment from 'moment';
const convertStylesToBeeStyleFormat = (
  styles = {},
  { isTitle = false } = {}
) => {
  const result = {};
  Object.keys(styles).forEach(key => {
    if (key === 'fontFamily') {
      result[isTitle ? 'font-family' : key] = _.get(
        styles,
        `${key}.fontFamily`
      );
    } else if (key === 'fontSize' || key === 'borderRadius') {
      result[key] = styles[key] ? `${styles[key]}px` : '';
    } else {
      result[key] = styles[key];
    }
  });

  return result;
};

let callBackFn = null;
let saveRowsArgs = null;
let saveDisplayConditionArgs = null;

/**
 * Returns a template json object with display condition removed from the given row.
 * @param {object} templateObj - The template json object from BEE editor.
 * @param {string} rowId - The uuid of the row from which display cond is to be removed.
 */
const removeDisplayCondFromRow = (templateObj, rowId) => {
  const newTemplateObj = { ...templateObj };
  const rows = get(newTemplateObj, 'page.rows', null);
  if (rows) {
    const newRows = rows.map(row => {
      if (row.uuid === rowId) {
        const container = row.container;
        const newRow = {
          ...row,
          container: omit(container, ['displayCondition'])
        };
        return newRow;
      }
      return row;
    });
    set(newTemplateObj, 'page.rows', newRows);
    return newTemplateObj;
  }
  return templateObj;
};

const EmailEditor = props => {
  const givenTemplate = useSelector(state => state.selectedTemplate);
  const { emailBriefing } = useSelector(state => state.emailDesign);
  const { authorizedUserData } = useSelector(state => state.authorized);
  const { countries, brands } = useSelector(state => state.dashboard);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isCopyUrl, setIsCopyUrl] = useState(false);
  //const [hasEmailBriefing, setHasEmailBriefing] = useState(false);
  const [isSaveRowOpen, setIsSaveRowOpen] = useState(false);
  const [isFilePickerOpen, setIsFilePickerOpen] = useState(false);
  const [filePickerType, setFilePickerType] = useState('src');
  const [isCustomRowBrandOpen, setIsCustomRowBrandOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [savedRowData, setSavedRowData] = useState({});
  const [customFilters, setCustomFilters] = useState();
  const [languageList, setLanguageList] = useState([]);
  const [isSaveDisplayConditionOpen, setIsSaveDisplayConditionOpen] = useState(
    false
  );
  const [isABTestVariantErrorOpen, setIsABTestVariantErrorOpen] = useState(
    false
  );
  const [savedDisplayCondition, setSavedDisplayCondition] = useState({});
  const [assignedVariants, setAssignedVariants] = useState({});
  const [variantValidationError, setVariantValidationError] = useState({});
  const [currentImageOrFile, setCurrentImageOrFile] = useState({});
  const [isDeleteRowOpen, setIsDeleteRowOpen] = useState(false);
  const [loaderText, setLoaderText] = useState("");
  const removeDisplayCondition = useRef(false);

  const brand = Array.isArray(emailBriefing?.brand)
    ? emailBriefing?.isCrossBrand
      ? null
      : emailBriefing?.brand[0]
    : emailBriefing?.brand;

  const { flyoutOpen = false } = props.history.location.state || {};
  const hasEmailBriefing = !isEmpty(emailBriefing);

  const handleDeleteModal = () => {
    setIsDeleteRowOpen(true);
  };
  const closeDeleteRow = () => {
    callBackFn(new Error('Cant delete row'));
    setIsDeleteRowOpen(false);
    callBackFn = null;
  };

  const handleTemp = (args, cb) => {
    if (args.metadata && args.metadata._id) {
      getEmailRowBlockById(args.metadata._id)
        .then(response => {
          if (response) {
            let updateflag = false;
            // check if user is requestor or approver.
            if (
              authorizedUserData.group === 'requestor' ||
              authorizedUserData.group === 'approver'
            ) {
              // check if the row is generic or cross brand then open save new row flow.
              if (
                response.rowType === 'generic' ||
                response.rowType === 'crossBrand'
              ) {
                updateflag = false;
              } else {
                // allow edit if user has saved this perticular row.
                if (response.userEmail === authorizedUserData.emailId) {
                  updateflag = true;
                } else {
                  updateflag = false;
                }
              }
            } else {
              // allow edit to admin or ACS
              updateflag = true;
            }

            if (updateflag) {
              setIsSaveRowOpen(true);
              setSavedRowData(args.metadata);
              saveRowsArgs = args;
              callBackFn = cb;
            } else {
              setIsSaveRowOpen(true);
              saveRowsArgs = args;
              callBackFn = cb;
            }
          }
        })
        .catch(error => {
          if (error.response && error.response.data) {
            setIsSaveRowOpen(true);
            saveRowsArgs = args;
            callBackFn = cb;
          }
        });
    } else {
      setIsSaveRowOpen(true);
      saveRowsArgs = args;
      callBackFn = cb;
    }
  };

  /**
   * filePickerHandler is an info content dialog to replace SRC upload and link file
   * @param {*} args
   * @param {*} cb
   */

  const filePickerHandler = (args, cb) => {
    callBackFn = cb;

    if (args.url && typeof args.url === 'string') {
      const { id, extn, query } = imageURLComponents(args?.url);
      setCurrentImageOrFile({ id, extn, query });
    } else {
      setCurrentImageOrFile({});
    }

    const linkModules = [
      'buttonModule.link',
      'imageModule.link',
      'menuModule.link',
      'iconsModule.link'
    ];
    const srcModules = [
      'imageModule.src',
      'iconsModule.src',
      'socialModule.src',
      'row.backgroundImage',
      'body.backgroundImage'
    ];
    if (srcModules.includes(args.context)) {
      setIsFilePickerOpen(true);
      setFilePickerType('src');
    }
    if (linkModules.includes(args.context)) {
      setIsFilePickerOpen(true);
      setFilePickerType('link');
    }
  };

  const copyToClipboard = async evt => {
    let text = document.getElementsByClassName('copy-tab-url')[0].innerText;
    try {
      await navigator.clipboard.writeText(text);
      setIsCopyUrl(true);
    } catch (err) {
      setIsCopyUrl(false);
    }
    setTimeout(() => {
      setIsCopyUrl(false);
    }, 300);
  };

  const modifiedTemplate = addMetaLangToTemplate(
    givenTemplate.templateObject,
    emailBriefing
  );

  const handleDeleteRow = (args = {}, cb) => {
    const { row = {} } = args;
    if (row.metadata && row.metadata._id) {
      if (isUserRequestorOrApprover) {
        callBackFn = cb;
        handleDeleteModal();
      } else {
        dispatch(deleteEmailRow(row.metadata._id, cb));
      }
    }
  };

  const handleCustomRowBrand = cb => {
    setIsCustomRowBrandOpen(true);
    callBackFn = cb;
  };

  const handleSubmit = (data, setFormErrorMessage, action) => {
    const newRowData = {
      ...saveRowsArgs,
      metadata: data
    };

    const payload = {
      category: data.rowCategory.value,
      userEmail: data.userEmail,
      userName: data.userName,
      rowName: data.rowName,
      rowType: data.rowType,
      templateObject: newRowData
    };

    if (
      payload.rowType === 'brandSpecific' ||
      payload.rowType === 'crossBrand'
    ) {
      if (payload.rowType === 'brandSpecific' && Array.isArray(data.brand)) {
        payload.brand = data.brand[0];
      } else {
        payload.brand = data.brand;
      }

      payload.country = data.country;
    }

    const metadataForEditor = { ...data };

    if (action === 'update') {
      const _id = metadataForEditor._id;
      payload.updatedByUserEmail = payload.userEmail;
      payload.updatedByUserName = payload.userName;
      delete payload.userEmail;
      delete payload.userName;
      updateEmailRowBlock(_id, payload)
        .then(result => {
          if (result.status) {
            setIsSaveRowOpen(false);
            setSavedRowData({});
            callBackFn(null, metadataForEditor);
          } else {
            setFormErrorMessage(result.message);
            document
              .querySelector('.email-overlay-header-box')
              .scrollIntoView(true);
          }
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      saveEmailRowBlock(payload)
        .then(result => {
          if (result.status) {
            metadataForEditor._id = result.data._id;
            setIsSaveRowOpen(false);
            setSavedRowData({});
            callBackFn(null, metadataForEditor);
          } else {
            setFormErrorMessage(result.message);
            document
              .querySelector('.email-overlay-header-box')
              .scrollIntoView(true);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const handleCustomRowBrandSubmit = data => {
    console.log('in');
    let country = get(data, 'country.value', null);
    let brand = get(data, 'brand.value', null);
    const categoryList = get(data, 'rowCategory', []);
    const category = categoryList.map(item => item.value).join(',');
    const rowType = get(data, 'rowType', null);
    if (rowType === 'generic') {
      brand = null;
      country = null;
    }
    let query = '';

    if (country && !brand && !category) {
      query = `country|-|${country}`;
    } else if (country && brand && !category) {
      query = `brand&country|${brand}|${country}`;
    } else if (country && brand && category && !rowType) {
      query = `brand&country&category|${brand}|${country}|${category}`;
    } else if (!country && brand && !category) {
      query = `brand|${brand}`;
    } else if (!country && !brand && !rowType && category) {
      query = `category|${category}`;
    } else if (!country && !brand && !category && rowType) {
      query = `rowType|${rowType}`;
    } else if (rowType && country && !brand && category) {
      query = `rowType&country&category|${rowType}|${country}|${category}`;
    } else if (country && !brand && category) {
      query = `country&category|${country}|${category}`;
    } else if (rowType && !country && brand && category) {
      query = `rowType&brand&category|${rowType}|${brand}|${category}`;
    } else if (!country && brand && category) {
      query = `brand&category|${brand}|${category}`;
    } else if (country && brand && category && rowType) {
      query = `rowType&category&brand&country|${rowType}|${category}|${brand}|${country}`;
    } else if (category && rowType) {
      query = `rowType&category|${rowType}|${category}`;
    } else {
      query = `all`;
    }
    console.log('------> query: ', query);
    const filterDetails = {
      name: 'Other brand tiles',
      value: query,
      handle: 'getRows', // to make async call
      isLocal: true
    };
    setIsCustomRowBrandOpen(false);
    setCustomFilters(data);
    callBackFn(null, filterDetails);
  };

  const onSaveAsTemplate = (json, html) => {
    const parsedJson = JSON.parse(json);
    dispatch(openSaveEmailTemplate(parsedJson, html));
  };

  const onSave = async (
    jsonFile,
    htmlFile = null,
    { isProcessing: actionType, changesDescription, callBack } = {}
  ) => {
    if (actionType === constants.ACTION_TYPES.SAVE_AS_TEMPLATE) {
      const parsedJson = JSON.parse(jsonFile);
      dispatch(openSaveEmailTemplate(parsedJson, htmlFile));
    } else if (actionType === constants.ACTION_TYPES.DOWNLOAD_TEMPLATE_IMAGE) {
      const uid = props.match.params.id;
      setIsLoading(true);
      const callback = () => {};
      const data = await saveEmailTemplateAndDownload(
        uid,
        jsonFile,
        htmlFile,
        { user: authorizedUserData },
        callback
      );
      const {
        result: {
          desktopImageBlob,
          mobileImageBlob,
          campaignName,
          countryCode,
          brandNames,
          variantDesktopImageArr,
          variantMobileImageArr
        }
      } = data;
      if (variantDesktopImageArr && variantDesktopImageArr.length) {
        for (const ele of variantDesktopImageArr) {
          const desktopBlob = getBlobFromBuffer(ele.imageBlob);
          fileDownload(
            desktopBlob,
            `CW_Email_${countryCode}_${brandNames}_${campaignName}_${moment().format(
              'DDMMYYYY'
            )}_Desktop_${constants.AB_VARIANT_MAP_IMG_DWNLOAD[ele.variant]}.jpg`
          );
        }
        for (const ele of variantMobileImageArr) {
          const mobileBlob = getBlobFromBuffer(ele.imageBlob);
          fileDownload(
            mobileBlob,
            `CW_Email_${countryCode}_${brandNames}_${campaignName}_${moment().format(
              'DDMMYYYY'
            )}_Mobile_${constants.AB_VARIANT_MAP_IMG_DWNLOAD[ele.variant]}.jpg`
          );
        }
        setIsLoading(false);
      } else {
        const desktopBlob = getBlobFromBuffer(desktopImageBlob);
        fileDownload(
          desktopBlob,
          `CW_Email_${countryCode}_${brandNames}_${campaignName}_${moment().format(
            'DDMMYYYY'
          )}_Desktop.jpg`
        );
        const mobileBlob = getBlobFromBuffer(mobileImageBlob);
        setIsLoading(false);
        fileDownload(
          mobileBlob,
          `CW_Email_${countryCode}_${brandNames}_${campaignName}_${moment().format(
            'DDMMYYYY'
          )}_Mobile.jpg`
        );
      }
    } else {
      const uid = props.match.params.id;
      const callback =
        actionType === constants.ACTION_TYPES.NEXT
          ? () => {
              if (
                props.history &&
                props.history.location &&
                props.history.location.state &&
                props.history.location.state.isEdited
              ) {
                if (
                  (authorizedUserData.group === 'acs' ||
                    authorizedUserData.group === 'admin') &&
                  emailBriefing &&
                  ['4', '8'].includes(emailBriefing.status)
                ) {
                  props.history.push(
                    `/campaign-wizard/emailbriefing/${uid}/execution`
                  );
                } else {
                  props.history.push(
                    `/campaign-wizard/emailbriefing/${uid}/approval`
                  );
                }
              } else {
                props.history.push(
                  '/campaign-wizard/emailbriefing/' + uid + '/review'
                );
              }
            }
          : actionType === constants.ACTION_TYPES.BACK
          ? () => {
              props.history.push(
                `/campaign-wizard/emailbriefing/${uid}/design-your-email`
              );
            }
          : actionType === constants.ACTION_TYPES.CHANGE_TEMPLATE
          ? () => {
              props.history.push(
                `/campaign-wizard/emailbriefing/${uid}/email-template`
              );
            }
          : callBack
          ? callBack
          : () => {};
      try {
        if (
          actionType ===
            constants.ACTION_TYPES.HTML_CHANGES_DESCRIPTION_SUBMIT ||
          actionType === constants.ACTION_TYPES.BACK ||
          actionType === constants.ACTION_TYPES.CHANGE_TEMPLATE ||
          actionType === constants.ACTION_TYPES.NEXT ||
          actionType === constants.ACTION_TYPES.SAVE_AS_TEMPLATE ||
          actionType === constants.ACTION_TYPES.SEND_EMAIL ||
          actionType === constants.ACTION_TYPES.TRIGGER_VALIDATION ||
          actionType === constants.ACTION_TYPES.TRIGGER_VALIDATION_ASYNC
        )
          setIsLoading(true);
          if(actionType === constants.ACTION_TYPES.TRIGGER_VALIDATION_ASYNC) {
            setLoaderText("Saving and running tests");
          }
        const result = await saveEmailTemplate(
          uid,
          jsonFile,
          htmlFile,
          { user: authorizedUserData },
          callback
        );
        if (
          actionType === constants.ACTION_TYPES.HTML_CHANGES_DESCRIPTION_SUBMIT
        ) {
          const { name, emailId, group } = authorizedUserData;
          await updateEmailBriefing(uid, {
            htmlChangesDescriptionSubmission: true,
            changesDescription,
            userName: name,
            userEmail: emailId,
            userGroup: group
          });
        }
        if (
          actionType ===
            constants.ACTION_TYPES.HTML_CHANGES_DESCRIPTION_SUBMIT ||
          actionType === constants.ACTION_TYPES.BACK ||
          actionType === constants.ACTION_TYPES.CHANGE_TEMPLATE ||
          actionType === constants.ACTION_TYPES.NEXT ||
          actionType === constants.ACTION_TYPES.SAVE_AS_TEMPLATE
        )
          //setIsLoading(false);
          dispatch({
            type: UPDATE_TEMPLATE_OBJECT,
            payload: JSON.parse(jsonFile)
          });
        setIsLoading(false);
        setLoaderText("");
        return result;
      } catch (error) {
        setIsLoading(false);
        setLoaderText("");
      }
    }
  };

  const onChange = (jsonFile, response) => {
    const parsedJson = JSON.parse(jsonFile);
    const variantInfo = getRowAndVariantComboInfo(parsedJson);
    removeDisplayCondition.current = false;
    setAssignedVariants(variantInfo);
    return { modifiedJson: parsedJson, hasRemovedDisplayCondition: false };
  };

  const updateLastUpdatedScreeen = () => {
    const uid = props.match.params.id;
    const dataToSend = {
      userEmail: props.userEmail,
      userName: props.userName
    };
    dispatch(
      updateEmailCampaignDetails(uid, dataToSend, (error, result) => {})
    );
  };

  useEffect(() => {
    // fetch emailbriefings
    const uid = props.match.params.id;
    // if (!emailBriefing || emailBriefing.uid !== uid) {
    dispatch(getEmailBriefing(uid));
    // }
    // fetch country and brandlist.
    getBrandDetails().then(result => {
      if (result.languages) {
        let languages = result.languages.map(language => {
          return {
            value: language.code,
            label: language.name
          };
        });
        setLanguageList(languages);
      }
    });
    dispatch(getBrandCountries());
    dispatch(getBrands());

    const mainElement = document.querySelector('main');
    mainElement.classList.add('pb-0');
    return () => {
      mainElement.classList.remove('pb-0');
    };
  }, []);

  useEffect(() => {
    // fetch template from given template id/name
    // fetch logic
    const uid = props.match.params.id;
    setIsLoading(true);
    // if (!isEmpty(givenTemplate)) {
    //   console.log('>>>>>>>>>>>>>>>>> not empty::',givenTemplate.templateObject )
    //   setIsLoading(false);
    //   setTemplate(givenTemplate.templateObject);
    // } else {
    getEmailTemplateEditByUid(uid)
      .then(result => {
        setIsLoading(false);
        if (!isEmpty(result)) {
          dispatch({
            type: UPDATE_TEMPLATE_OBJECT,
            payload: result.templateObject
          });
          const variantInfo = getRowAndVariantComboInfo(result.templateObject);
          setAssignedVariants(variantInfo);
        }
      })
      .catch(error => {
        console.log('getEmailTemplateEditByUid error', error);
      });
    //}
    updateLastUpdatedScreeen();
  }, []);

  useEffect(() => {
    if (brand && brand._id) {
      dispatch(fetchBrandDefaults(brand._id));
    }
  }, [brand]);

  const refetchEmailBriefing = () => {
    const uid = props.match.params.id;
    // if (!emailBriefing || emailBriefing.uid !== uid) {
    dispatch(getEmailBriefing(uid));
  };

  const beeConfig = {
    language: 'en-US',
    autosave: true
  };

  const brandDefaults = useSelector(state => state.brandDefaults);

  if (brandDefaults.data) {
    const {
      colors = [],
      buttons = {},
      title = {},
      paragraph = {},
      links = {}
    } = brandDefaults.data;

    const linkColor = links.color;

    beeConfig.defaultColors = colors.map(color => color.code);
    beeConfig.contentDefaults = {
      button: {
        styles: convertStylesToBeeStyleFormat(buttons)
      },
      paragraph: {
        styles: convertStylesToBeeStyleFormat(paragraph)
      },
      general: {
        linkColor
      }
    };
    if (title) {
      beeConfig.titleDefaultStyles = {
        h1: convertStylesToBeeStyleFormat(title, { isTitle: true }),
        h2: convertStylesToBeeStyleFormat(title, { isTitle: true }),
        h3: convertStylesToBeeStyleFormat(title, { isTitle: true })
      };
    }
    if (linkColor) {
      beeConfig.contentDefaults = _.merge(beeConfig.contentDefaults, {
        text: {
          styles: {
            linkColor
          }
        },
        paragraph: {
          styles: {
            linkColor
          }
        },
        list: {
          styles: {
            linkColor
          }
        },
        menu: {
          style: { linkColor }
        }
      });

      beeConfig.titleDefaultStyles = _.merge(
        beeConfig.titleDefaultStyles || {},
        {
          h1: { 'link-color': linkColor },
          h2: { 'link-color': linkColor },
          h3: { 'link-color': linkColor }
        }
      );
    }
  }

  const openTestEmailModel = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => [setModalIsOpen(false)];

  useEffect(() => {}, [beeConfig.defaultColors]);

  const checkcond = () => {
    if (hasEmailBriefing) {
      if (
        givenTemplate &&
        !isEmpty(givenTemplate.templateObject) &&
        !isEmpty(languageList)
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  const isEmailAdminOrACS = checkIsEmailAdminOrACS(authorizedUserData);
  const isUserRequestorOrApprover = checkIfUserIsRequestorOrApprover(
    authorizedUserData
  );

  const handleDisplayConditionDialog = (args, cb) => {
    setIsSaveDisplayConditionOpen(true);
    setSavedDisplayCondition(args);
    //saveDisplayConditionArgs = args;
    callBackFn = cb;
  };

  const handleDisplayConditionSubmit = (
    data,
    { savedDisplayCondition: savedCond }
  ) => {
    /* Check if the selected display condition is not same as already saved one.
      If it is then do nothing */
    if (data.id !== savedCond.id) {
      /* Check if the given display condition is already applied on a row. 
          If yes then store the rowId in ref variable. This will be used in
          onChange event callback. */
      if (assignedVariants[data.id].isAssigned) {
        removeDisplayCondition.current = assignedVariants[data.id].rowId;
      }
    }
    setIsSaveDisplayConditionOpen(false);
    setSavedDisplayCondition({});
    callBackFn(null, data);
  };

  const handleABTestVariantValidation = () => {
    const noOfABVariants = get(emailBriefing, 'abTesting.noOfABVariants');
    if (noOfABVariants === '2') {
      const missingVariants = [];
      if (!assignedVariants.variantA.isAssigned) {
        missingVariants.push('Variant A');
      }
      if (!assignedVariants.variantB.isAssigned) {
        missingVariants.push('Variant B');
      }
      if (missingVariants.length > 0) {
        setVariantValidationError({
          testType: 'AB',
          error:
            missingVariants.length === 2
              ? 'any variant'
              : missingVariants.join(' and ')
        });
        setIsABTestVariantErrorOpen(true);
        return true;
      } else {
        return false;
      }
    } else {
      const missingVariants = [];
      if (!assignedVariants.variantA.isAssigned) {
        missingVariants.push('Variant A');
      }
      if (!assignedVariants.variantB.isAssigned) {
        missingVariants.push('Variant B');
      }
      if (!assignedVariants.variantC.isAssigned) {
        missingVariants.push('Variant C');
      }
      if (missingVariants.length > 0) {
        setVariantValidationError({
          testType: 'ABC',
          error:
            missingVariants.length === 3
              ? 'any variant'
              : missingVariants.join(' and ')
        });
        setIsABTestVariantErrorOpen(true);
        return true;
      } else {
        return false;
      }
    }
  };

  const handleSetImage = url => {
    callBackFn(null, url);
    setIsFilePickerOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="cw-modal cw-modal--test-email-model"
        contentLabel="Field Types"
        style={{
          overlay: {
            backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
          }
        }}
      >
        <EmailTest
          onClose={closeModal}
          userEmail={props.userEmail}
          uid={props.match.params.id}
          userName={props.userName}
          userGroup={authorizedUserData.group}
          emailBriefing={emailBriefing}
          refetchEmailBriefing={refetchEmailBriefing}
        />
      </Modal>
      <div className="col-sm-12 skypink page-edit-template">
        <div className="cw-section page-edit-template_wrapper">
          <div>
            {isLoading ? <LoaderWithText text={loaderText} /> : null}
            {checkcond() ? (
              <>
                {/* <button   className="btn btn-primary mb-20 mt-15 ml-auto" onClick={openTestEmailModel}> Send test email</button> */}
                <BeeEmailEditor
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  flyoutOpen={flyoutOpen}
                  config={beeConfig}
                  defaultColors={beeConfig.defaultColors}
                  template={modifiedTemplate}
                  saveRows={isEmailAdminOrACS || isUserRequestorOrApprover}
                  onSaveRow={handleTemp}
                  onDeleteRow={handleDeleteRow}
                  onCustomRowBrand={handleCustomRowBrand}
                  emailBriefing={emailBriefing}
                  onSaveAsTemplate={onSaveAsTemplate}
                  onSave={onSave}
                  onChange={onChange}
                  onSend={openTestEmailModel}
                  history={props.history}
                  uid={props.match.params.id}
                  refetchEmailBriefing={refetchEmailBriefing}
                  authorizedUserData={authorizedUserData}
                  onFilePicker={filePickerHandler}
                  languageList={languageList}
                  onDisplayCondition={handleDisplayConditionDialog}
                  onABTestVariantValidation={handleABTestVariantValidation}
                />
              </>
            ) : null}

            <div className="col-md-12"></div>
            {/* <div className="col-md-12">
            <LinkButton className="cw-formdraft-link mt-40" onClick={() => {}}>
              Save and exit
            </LinkButton>
          </div> */}
          </div>

          {filePickerType === 'src' ? (
            <GenerateImagePathModal
              isOpen={isFilePickerOpen}
              handleCloseForm={() => {
                setIsFilePickerOpen(false);
                callBackFn(new Error('Closed'), null);
              }}
              handleSubmit={handleSetImage}
              type={filePickerType}
              copyToClipboard={copyToClipboard}
              isCopyUrl={isCopyUrl}
              currentImage={currentImageOrFile}
              savedRowData={savedRowData}
            />
          ) : (
            <GenerateFilePathModal
              isOpen={isFilePickerOpen}
              handleCloseForm={() => {
                setIsFilePickerOpen(false);
                callBackFn(new Error('Closed'), null);
              }}
              handleSubmit={handleSetImage}
              type={filePickerType}
              copyToClipboard={copyToClipboard}
              isCopyUrl={isCopyUrl}
              currentImage={currentImageOrFile}
              savedRowData={savedRowData}
            />
          )}
          <SaveRowModel
            isOpen={isSaveRowOpen}
            handleCloseForm={() => {
              setIsSaveRowOpen(false);
              setSavedRowData({});
              callBackFn(new Error('Closed'), null);
            }}
            handleSubmit={handleSubmit}
            user={authorizedUserData}
            emailBriefing={emailBriefing}
            savedRowData={savedRowData}
            isUserRequestorOrApprover={isUserRequestorOrApprover}
          />
          <CustomBrandRowsModel
            isOpen={isCustomRowBrandOpen}
            handleCloseForm={() => {
              setIsCustomRowBrandOpen(false);
              const filterDetails = {
                name: 'Other brand tiles',
                value: 'cancel',
                handle: 'getRows', // to make async call
                isLocal: true
              };
              callBackFn(null, filterDetails);
            }}
            handleSubmit={handleCustomRowBrandSubmit}
            user={authorizedUserData}
            emailBriefing={emailBriefing}
            dispatch={dispatch}
            countries={countries}
            brands={brands}
            customFilters={customFilters}
          />
          <SaveDisplayConditions
            isOpen={isSaveDisplayConditionOpen}
            handleCloseForm={() => {
              setIsSaveDisplayConditionOpen(false);
              setSavedDisplayCondition({});
              callBackFn(new Error('Closed'), null);
            }}
            handleSubmit={handleDisplayConditionSubmit}
            user={authorizedUserData}
            emailBriefing={emailBriefing}
            savedDisplayCondition={savedDisplayCondition}
            assignedVariants={assignedVariants}
          />
          <ABTestingVariantValidation
            isOpen={isABTestVariantErrorOpen}
            handleCloseModal={() => {
              setIsABTestVariantErrorOpen(false);
            }}
            variantValidationError={variantValidationError}
          />
          <DeleteRowModal
            isOpen={isDeleteRowOpen}
            handleCloseModal={closeDeleteRow}
            handleConfirmation={() => {}}
            isUserRequestorOrApprover={isUserRequestorOrApprover}
          />
        </div>
      </div>
    </>
  );
};

export default EmailEditor;
