import React, { useState, useEffect, useCallback } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import 'semantic-ui-css/components/accordion.min.css';
import BreadCrumb from '../../components/BreadCrumb';
import QnaGuideline from 'components/QnaGuideline';
import Modal from 'react-modal';
import axios from 'axios';
import constants from '../../constants';
import QnAListEnrichment from 'components/SAAS/QnAListEnrichment';

import {
  debounceFunction,
  getDistinctBusinessFilters
} from 'selectors/index.js';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const ConsumerDataEnrichment = props => {
  const [closeGuideModal, setCloseGuideModal] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [businessFilters, setBusinessFilter] = useState([]);
  const isOpenGuidelineModel = () => {
    setCloseGuideModal(true);
  };
  const [activeTab, setActiveTab] = useState(0);
  const [searchQuestionsQuery, setSearchQuestionsQuery] = useState('');
  const [searchAnswersQuery, setSearchAnswersQuery] = useState('');
  const [selectedBusinessFilters, setSelectedBusinessFilters] = useState('');

  const changeTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const isCloseGuidelineModel = () => {
    setCloseGuideModal(false);
  };

  let fetchQuestionList = async () => {
    let queUrl =
      constants.serviceUrls.FETCH_ALL_QUESTIONS + '?language=' + 'en';
    await axios.get(queUrl).then(response => {
      let data = response && response.data;
      setQuestions(data);
      setBusinessFilter(getDistinctBusinessFilters(data));
    });
  };
  let fetchAnswersList = async () => {
    let ansUrl = constants.serviceUrls.FETCH_ALL_ANSWERS + '?language=' + 'en';
    await axios.get(ansUrl).then(response => {
      let data = response && response.data;
      setAnswers(data);
    });
  };

  let searchAnswers = text => {
    axios
      .get(
        constants.serviceUrls.FETCH_ALL_ANSWERS +
          '?language=' +
          'en' +
          '&search=' +
          text
      )
      .then(response => {
        let data = response && response.data;
        setAnswers(data);
      });
  };

  let searchQuestions = (text, group) => {
    let url =
      constants.serviceUrls.FETCH_ALL_QUESTIONS +
      '?language=' +
      'en' +
      '&search=' +
      text;
    if (group && group !== '') {
      url = url + ' &group=' + encodeURIComponent(group);
    }
    axios.get(url).then(response => {
      let data = response && response.data;
      setQuestions(data);
    });
  };

  useEffect(() => {
    fetchQuestionList();
    fetchAnswersList();
  }, []);

  useEffect(() => {
    optimizedQuestionVersion(searchQuestionsQuery, selectedBusinessFilters);
  }, [searchQuestionsQuery, selectedBusinessFilters]);

  useEffect(() => {
    optimizedAnswerVersion(searchAnswersQuery);
  }, [searchAnswersQuery]);

  const optimizedAnswerVersion = useCallback(
    debounceFunction(searchAnswers, 600),
    []
  );
  const optimizedQuestionVersion = useCallback(
    debounceFunction(searchQuestions, 600),
    []
  );

  const tabStyle = { fontFamily: 'Open Sans', textTransform: 'none' };

  return (
    <div className="cw-section cw-overview cw-releasenote-wrapper ml-20">
      <div className="col-sm-12">
        <BreadCrumb match={props.match} hideBreadCrumb={false} />
      </div>
      <div className="col-sm-12">
        <div className="ml-20 mb-10">
          <h2 className="cw-heading--secondary d-flex align-items-center mb-10">
            <span className="mr-3">Consumer data enrichment</span>
          </h2>
          <p className="mb-10">
            Plan your consumer data enrichment initiatives using our curated
            master list of questions and answers for inspiration. These area
            readily available with already provisioned master data IDs, which
            means your campaign setup with these should be quick.
          </p>

          <p>
            To create a data capture campaign or to request new Q&As, use our{' '}
            <a href="#" onClick={isOpenGuidelineModel}>
              guidelines
            </a>
            , and{' '}
            <a href="/campaign-wizard/newcampaign">create a campaign request</a>
            .
          </p>
        </div>

        <div>
          <div className="ui top attached tabular menu qna-tab-overlay qna-tab-overlay-custom">
            <Tabs
              value={activeTab}
              onChange={changeTab}
              variant="scrollable"
              scrollButtons="auto"
              disableRipple
            >
              <Tab disableRipple style={tabStyle} label="Questions" />
              <Tab disableRipple style={tabStyle} label="Answers" />
            </Tabs>
          </div>

          <TabPanel value={activeTab} index={0}>
            <QnAListEnrichment
              showQuestions={true}
              questions={questions}
              businessFilters={businessFilters}
              searchQuestionsQuery={searchQuestionsQuery}
              setSearchQuestionsQuery={setSearchQuestionsQuery}
              selectedBusinessFilters={selectedBusinessFilters}
              setSelectedBusinessFilters={setSelectedBusinessFilters}
              searchQuestions={optimizedQuestionVersion}
              campaignLanguage={'English'}
            />
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <QnAListEnrichment
              showQuestions={false}
              answers={answers}
              searchAnswersQuery={searchAnswersQuery}
              setSearchAnswersQuery={setSearchAnswersQuery}
              searchAnswers={optimizedAnswerVersion}
              campaignLanguage={'English'}
            />
          </TabPanel>

          <div className="ml-20 mt-2">
            <a
              className="cw-qna-faq-link"
              href={constants.QNA_FAQ_LINK}
              target="_blank"
              rel="noreferrer"
            >
              Q&A FAQs <i className="fas fa-external-link-alt"></i>
            </a>
          </div>
        </div>

        <Modal
          scrollable={true}
          isOpen={closeGuideModal}
          onRequestClose={isCloseGuidelineModel}
          className="cw-modal cw-modal--qnadetail"
          contentLabel="Campaign Wizard 2.0"
          style={{
            overlay: {
              backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
            }
          }}
        >
          <QnaGuideline closeIsEditModal={isCloseGuidelineModel} />
        </Modal>
      </div>
    </div>
  );
};
export default ConsumerDataEnrichment;
