import React from 'react';
import RichTextEditor from '../../richTextEditor';
import Modal from 'react-modal';
import ConsentGuidelines from '../../ConsentGuidelines';
import constant from '../../../constants';
import { ConsentAvailabilityMessage } from '../../atoms';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import ReactTooltip from 'react-tooltip';
import { isConsentFieldEditable } from 'selectors';
import ReactHtmlParser from 'react-html-parser';
import { RichTextPreviewOnly } from '../../richTextEditor';

const {
  consentTypes,
  UNEDITABLE_CONSENTS,
  NONRESETTABLE_CONSETS,
  DEFAULT_EDITABLE_CONSETS
} = constant;

export default class ConsentStatement extends React.Component {
  state = {
    modalIsOpen: false
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  additionalConsentRichTextData = (richTextData, optInType) => {
    const { onConsentStatementChange } = this.props;
    const defaultCopy = richTextData.getContent();
    document.getElementById(`consent_error_${optInType}`) &&
      document
        .getElementById(`consent_error_${optInType}`)
        .classList.add('cw-hidden');

    document
      .getElementById(`consent_${optInType}`)
      .classList.contains('cw-error-focus') &&
      document
        .getElementById(`consent_${optInType}`)
        .classList.remove('cw-error-focus');

    onConsentStatementChange(defaultCopy);
  };

  render() {
    const {
      elem,
      index,
      consentValue,
      consentsUnavailable,
      consentDefaultValue,
      _handleReset,
      _handleLatastApprovedConsent,
      userrole,
      campaignConfig,
      isEpsilonMarket,
      id,
      isOpenMultiChannelGuideModel
    } = this.props;

    const { modalIsOpen } = this.state;

    const isEditable = isConsentFieldEditable({
      field: elem.optInType,
      defaultValue: consentDefaultValue,
      role: userrole,
      campaignConfig,
      isEpsilonMarket
    });

    const isDisabled = DEFAULT_EDITABLE_CONSETS.includes(elem.optInType.trim())
      ? false
      : !isEditable;
    const enableRichTextPreviewOnly = this.props?.campaignConfig
      ?.ENABLE_RICHTEXT_PREVIEW_ONLY;

    return (
      <>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          className="cw-modal cw-modal--questions"
          contentLabel="Field Types"
          style={{
            overlay: {
              backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
            }
          }}
        >
          <ConsentGuidelines onClose={this.closeModal} />
        </Modal>

        {![
          'termsAndConditions',
          'legalDisclaimer',
          'contentInterestAreas'
        ].includes(elem.optInType) &&
          (!isEditable ? (
            consentsUnavailable ? (
              <ConsentAvailabilityMessage
                textBeforeLink="We do not have DPO approved enterprise consents statements for your brand/country. Please raise an "
                textAfterLink=" ticket to get these added to central records. Unilever DPOs mandate that our forms use the approved consent statements we auto-populate for you from a central repository. We strongly advise not editing consent statements in Campaign Wizard as this can lead to legal audits and implications for the brand/requestor. Please note that the ability to edit consents in Campaign Wizard is temporary and will be disabled shortly. "
                openModal={this.openModal}
                elem={elem}
              />
            ) : (
              <ConsentAvailabilityMessage
                textBeforeLink="Unilever DPOs mandate that our forms use the approved consent statements we auto-populate for you from a central repository. You will not be able to edit these in Campaign Wizard.  If your consent statements are missing or require any corrections and updates please raise an "
                textAfterLink=". "
                openModal={this.openModal}
                elem={elem}
              />
            )
          ) : (
            <ConsentAvailabilityMessage
              textBeforeLink="Unilever DPOs mandate that our forms use the approved consent statements we auto-populate for you from a central repository. If your consent statements are missing or require any corrections and updates please raise an "
              textAfterLink=" ticket to get these corrected in central records for your country. We <strong>strongly advise not editing consent statements</strong> in Campaign Wizard as this can lead to legal audits and implications for the brand/requestor. "
              openModal={this.openModal}
              elem={elem}
            />
          ))}

        {elem.optInType === consentTypes.multiChannelUnileverConsent && (
          <>
            {' '}
            <div>
              <label htmlFor={`defaultCopy_${elem.optInType}`}>
                <span>Select applicable channels</span>
              </label>
            </div>
            <div className="d-flex mt-20">
              {elem.multi_consents_value.map((el, multiConsentIndex) => (
                <div className="form-group mr-2" key={multiConsentIndex}>
                  <div className="row">
                    <div className="col-4">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={el.isChecked}
                          // defaultChecked={el.isChecked}
                          disabled={el.disabled}
                          onChange={e => {
                            this.props.handleMultiChannelCheckboxClick(
                              e,
                              index,
                              multiConsentIndex
                            );
                          }}
                          name={el.value}
                          id={el.value}
                        />
                        <label className="form-check-label" htmlFor={el.value}>
                          {el.value}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        {elem.optInType !== consentTypes.contentInterestAreas && (
          <div key={id} className="form-group" style={{ margin: '2% 0 2% 1%' }}>
            <label htmlFor={`defaultCopy_${elem.optInType}`}>
              <span>Consent Statement</span>
              {!isEpsilonMarket &&
              campaignConfig.MULTI_CHANNEL_CONSENT_ENABLE ? (
                <>
                  <a
                    href="#modal"
                    onClick={isOpenMultiChannelGuideModel}
                    title={`Consent guidelines`}
                    className="text-link-right"
                    style={{ float: 'right' }}
                  >
                    {`Consent guidelines`}
                  </a>
                </>
              ) : null}
            </label>
            <div id={`consent_${elem.optInType}`}>
              {isDisabled && enableRichTextPreviewOnly ? (
                <>
                  <RichTextPreviewOnly
                    defaultValue={consentValue}
                    placeholder={consentDefaultValue}
                  />
                </>
              ) : (
                <RichTextEditor
                  disabled={
                    DEFAULT_EDITABLE_CONSETS.includes(elem.optInType.trim())
                      ? false
                      : !isEditable
                  }
                  defaultValue={consentDefaultValue}
                  value={consentValue}
                  parentCallback={textData => {
                    this.additionalConsentRichTextData(
                      textData,
                      elem.optInType
                    );
                  }}
                  id={`consent_RTE_${elem.optInType}`}
                />
              )}
            </div>
            <ErrorMessage
              id={`consent_error_${elem.optInType}`}
              message="Please provide the consent statement"
            />
          </div>
        )}
        {//elem.consentType !== 'implicit' &&
        !NONRESETTABLE_CONSETS.includes(elem.optInType) ? (
          <div
            className="align-items-center d-flex"
            style={{ margin: '0 0 0 1%' }}
          >
            {isEditable && (
              <button
                type="submit"
                className="inline-button text-decoration-underline mr-3"
                onClick={event => _handleReset(event, index, elem.optInType)}
              >
                Reset to default
              </button>
            )}
            <ReactTooltip
              id="fetchBtnToolTip"
              place="top"
              type="info"
              multiline={true}
              className="cw-toolip custom-cw-toolip"
            />
            <button
              type="submit"
              className="inline-button text-decoration-underline"
              onClick={event => _handleLatastApprovedConsent(event, index)}
            >
              Fetch latest approved consents
            </button>
            <span
              className="cw-icon cw-icon--help ml-2"
              data-tip={`You can retrieve the latest approved consent statement available in DPO approved central repository`}
              currentitem="false"
              data-for="fetchBtnToolTip"
            >
              <i className="fas fa-question-circle"></i>
            </span>
          </div>
        ) : null}
      </>
    );
  }
}
