import React from 'react';

import MissingImage from '../../../images/template-missing-image.png';
import MissingLink from '../../../images/template-missing-link.png';
import MissingABTest from '../../../images/template-missing-abtest.png';
import MissingCoupon from '../../../images/template-missing-coupon.png';

export const InitializeTestsBody = () => (
  <div className="template-tests">
    <p className="mt-3 mb-5 template-tests--desc">
      We will run automated tests to flag the below errors to give you peace of
      mind when it comes to your email design.
    </p>

    <div className="test-items mt-3">
      <div className="test-item mb-4 d-flex">
        <img src={MissingImage} />
        <div className="ml-3">
          <p className="test-item-title mb-1">Images</p>
          <span>Missing image alt text or invalid image URLs</span>
        </div>
      </div>

      <div className="test-item mb-4 d-flex">
        <img src={MissingLink} />
        <div className="ml-3">
          <p className="test-item-title mb-1">
            Links and mandatory personalized fields
          </p>
          <span>
            Missing button URLs, links, unsubscribe, mirror link, brand of
            origin and brand of origin email
          </span>
        </div>
      </div>

      <div className="test-item mb-4 d-flex">
        <img src={MissingABTest} />
        <div className="ml-3">
          <p className="test-item-title mb-1">A/B tests</p>
          <span>Missing or duplicate A/B test Variants, if applicable</span>
        </div>
      </div>

      <div className="test-item mb-4 d-flex">
        <img src={MissingCoupon} />
        <div className="ml-3">
          <p className="test-item-title mb-1">Coupons</p>
          <span>Missing dynamic coupons placeholders, if applicable</span>
        </div>
      </div>
    </div>
    <p className="template-tests-note mb-4 text-left">
      Please note that after you submit your campaign request, ACS team will run
      more detailed tests using Email on Acid for checking rendering across
      email clients and accessibility compliance.
    </p>
  </div>
);

export const InitializeTestsActions = ({ closeModal, onNext }) => (
  <>
    <div className="d-flex justify-content-between align-items-center">
      <a href="#" className="actions-link">
        {/* Previous test results */}
      </a>
      <div>
        <button className="btn btn btn btn-primary mr-3" onClick={onNext}>
          Start test
        </button>
        <button
          className="btn btn btn btn-outline-primary"
          onClick={() => closeModal(false)}
        >
          Close
        </button>
      </div>
    </div>
  </>
);
