import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BeePlugin from '@mailupinc/bee-plugin';
import constants from '../../../constants';
import blankTemplate from './blackTemplate';
import axios from 'axios';
import { connect, useSelector } from 'react-redux';
import { getCampaignConfig } from 'actions/app';
import {
  saveEmailRowBlock,
  updateChangesDescription,
  updateEmailRowBlock
} from 'services/emailBriefings';
import _, { isEmpty } from 'lodash';
import logo from 'images/CW_logo.svg';
import gridIcon from 'images/grid-icon.svg';
import ActionBar from '../ActionBar/ActionBar';
import ReactTooltip from 'react-tooltip';
import FlyoutModal from '../FlyoutModal';
import SaveSubjectAndPreheader from './saveSubjectAndPreheader';
import {
  checkIsEmailAdminOrACS,
  hasEmailContentABTest,
  applyDefaultStyles,
  checkIfUserIsRequestorOrApprover
} from 'selectors';
import HTMLChangesDescription from '../HTMLChangesDescription/HTMLChangesDescription';
import Loader from 'components/Loader';
import { DONT_SEND } from 'components/molecules/EmailScheduleInput';
import EmailDesignChecklistModal from 'components/molecules/EmailDesignChecklistModal/EmailDesignChecklistModal';
import EmailABTestingGuidelines from 'components/molecules/EmailABTestingGuidelines';
import EmailCouponGuidelines from 'components/molecules/EmailCouponGuidelines';
import { ApplyBrandStyleModal } from 'components/molecules/ApplyBrandStyleModal';
import ActionEmailChecklistIcon from 'images/email-checklist.png';
import ChangeTemplateIcon from 'images/change-icon.svg';
import ApplyBrandStylesIcon from 'images/apply-brand-styles-icon.svg';
import ABTestGuidelineIcon from 'images/abTestGuidelineIcon.svg';
import EmailCouponIcon from 'images/email-coupon-guideline-icon.svg';

const getFilterRows = (
  brands,
  country,
  type,
  onCustomRowBrand,
  isCrossBrand
) => {
  let brand = Array.isArray(brands) ? brands[0] : brands;

  if (type === 'simple') {
    let list = [
      {
        name: 'Marketing blocks',
        value: `rowType&category|generic|marketingBlocks`,
        handle: 'getRows', // to make async call
        isLocal: true
      },
      {
        name: 'Galleries',
        value: `rowType&category|generic|galleries`,
        handle: 'getRows', // to make async call
        isLocal: true
      },
      {
        name: 'Coupons',
        value: `rowType&category|generic|coupons`,
        handle: 'getRows', // to make async call
        isLocal: true
      },
      {
        name: 'Generic headers',
        value: `rowType&category|generic|headers`,
        handle: 'getRows', // to make async call
        isLocal: true
      },
      {
        name: 'Generic footers',
        value: `rowType&category|generic|footers`,
        handle: 'getRows', // to make async call
        isLocal: true
      }
    ];

    if (isCrossBrand) {
      list.push(
        {
          name: `Cross brand headers`,
          value: `brand&country&category&rowType|${brands
            .map(b => b.code)
            .join(',')}|${country.code}|headers|crossBrand`,
          handle: 'getRows', // to make async call
          isLocal: true
        },
        {
          name: `Cross brand footers`,
          value: `brand&country&category&rowType|${brands
            .map(b => b.code)
            .join(',')}|${country.code}|footers|crossBrand`,
          handle: 'getRows', // to make async call
          isLocal: true
        }
      );
    } else {
      if (brand.code && country.code) {
        list.push({
          name: `Headers - ${brand.name} ${country.name}`,
          value: `brand&country&category|${brand.code}|${country.code}|headers`,
          handle: 'getRows', // to make async call
          isLocal: true
        });

        list.push({
          name: `Footers - ${brand.name} ${country.name}`,
          value: `brand&country&category|${brand.code}|${country.code}|footers`,
          handle: 'getRows', // to make async call
          isLocal: true
        });
        list.push({
          name: `Marketing tiles - ${brand.name} ${country.name}`,
          value: `brand&country&category|${brand.code}|${country.code}|marketingBlocks`,
          handle: 'getRows', // to make async call
          isLocal: true
        });
        list.push({
          name: `Galleries - ${brand.name} ${country.name}`,
          value: `brand&country&category|${brand.code}|${country.code}|galleries`,
          handle: 'getRows', // to make async call
          isLocal: true
        });
        list.push({
          name: `Coupons - ${brand.name} ${country.name}`,
          value: `brand&country&category|${brand.code}|${country.code}|coupons`,
          handle: 'getRows', // to make async call
          isLocal: true
        });
      } else {
        list.push({
          name: `Headers`,
          value: `rowType&category|generic|headers`,
          handle: 'getRows', // to make async call
          isLocal: true
        });
        list.push({
          name: `Footers`,
          value: `rowType&category|generic|footers`,
          handle: 'getRows', // to make async call
          isLocal: true
        });
      }
    }

    return list;
  } else {
    if (brand.code && country.code) {
      return {
        label: 'Other brand tiles',
        handler: function(resolve, reject) {
          onCustomRowBrand((error, result) => {
            if (error) {
              reject(error);
            } else {
              resolve(result);
            }
          });
        }
      };
    } else {
      return null;
    }
  }
};

const getLanguageCode = emailBriefing => {
  const { language, country } = emailBriefing;
  if (language.code && country.code) {
    return `${language.code.toLowerCase()}-${country.code.toUpperCase()}`;
  }
  return null;
};

const getLanguageConfig = langCode => {
  if (langCode && constants.EMAIL_EDITOR_LANGUAGES_LIST.includes(langCode)) {
    return langCode;
  }
  return 'en-US';
};

const getMetaDataLanguageConfig = langauges => {
  if (!isEmpty(langauges)) {
    return {
      metadata: {
        languages: langauges
      }
    };
  }
  return {};
};

const getRTLConfig = langCode => {
  if (langCode) {
    const lang = langCode.split('-');
    if (constants.EMAIL_RTL_LANGUAGES.includes(lang[0])) {
      return {
        contentDefaults: {
          paragraph: {
            // ok
            styles: {
              direction: 'rtl'
            }
          },
          list: {
            //ok
            styles: {
              direction: 'rtl'
            }
          },
          button: {
            //ok
            styles: {
              direction: 'rtl'
            }
          }
        },
        titleDefaultStyles: {
          // ok
          h1: {
            direction: 'rtl'
          }
        }
      };
    }
    return {};
  }
  return {};
};

const isSubjectAndPreheaderNotPresent = emailBriefing => {
  const firstAttempt = _.get(emailBriefing, 'firstAttempt', {});
  const followUp = _.get(emailBriefing, 'followUp', {});
  const sendAfter = _.get(emailBriefing, 'deliverySchedule.sendAfter', {});
  if (sendAfter.value === DONT_SEND) {
    return !firstAttempt.subject || !firstAttempt.preheader;
  } else {
    return (
      !followUp.subject ||
      !followUp.preheader ||
      !firstAttempt.subject ||
      !firstAttempt.preheader
    );
  }
};

const DEFAULT_CONFIGURATION = {
  uid: '62cff773e172575e338aa060', // Needed for identify resources of the user.
  container: 'bee-plugin-container', // Identifies the id of div element that contains BEE Plugin.
  language: 'en-US',
  autosave: true,
  mergeTags: constants.BEE_EDITOR_SETUP.mergeTags,
  specialLinks: constants.BEE_EDITOR_SETUP.specialLinks,

  hooks: {
    getRows: {
      handler: async (resolve, reject, args) => {
        axios
          .get(constants.serviceUrls.EMAIL_BEE_GET_ROWS_URL, {
            params: { q: args.value }
          })
          .then(function(res) {
            return resolve(res.data);
          })
          .catch(function(err) {
            return reject(err);
          });
      }
    }
  },
  contentDialog: {
    externalContentURLs: {
      label: 'Search products',
      handler: function(resolve, reject) {
        // Your function
        resolve('hello');
      }
    }
  },
  translations: {
    'bee-common-widget-bar': {
      'empty-rows': 'Layouts',
      structure: 'Tiles',
      'empty-state': 'No tiles were found.',
      'row-properties': 'Tile properties',
      'structure-single-row': 'Tile',
      'edit-synced-row': 'Edit Synced Tile'
    },
    'mailup-bee-common-widgets-background-color-row': {
      label: 'Tile background color'
    },
    'mailup-bee-common-widgets-background-image': {
      label: 'Tile background image',
      row: 'Tile',
      'change-image': constants.featureFlags.EMAIL_UPLOAD_IMAGE_ENABLED
        ? 'Change image'
        : 'Image guidelines'
    },
    'mailup-bee-common-widgets-row-display-condition': {
      'no-condition': 'No display condition applied to this tile',
      add: 'Add A/B test condition'
    },
    'mailup-bee-newsletter-stage': {
      'delete-module-in-locked-row-error-message':
        'This tile is locked and therefore its content cannot be edited.',
      'delete-module-in-locked-row-error-title':
        'This content block cannot be deleted',
      'delete-row-locked-content-error-message':
        'This tile contains locked content and therefore cannot be deleted.',
      'delete-row-locked-content-error-title': 'This tile cannot be deleted',
      'duplicate-module-in-locked-row-error-message':
        'This tile is locked and therefore its content cannot be duplicated.',
      'duplicate-module-in-locked-row-error-title':
        'This content block cannot be duplicated',
      'duplicate-row-locked-content-error-message':
        'This tile contains locked content and therefore cannot be duplicated.',
      'duplicate-row-locked-content-error-title':
        'This tile cannot be duplicated',
      'locked-row-warning-message':
        'This tile is locked and therefore its content cannot be edited.',
      'locked-row-warning-title': 'This tile is locked',
      'hide-row-confirmation-button': 'Yes. Hide tile',
      'hide-row-confirmation-title':
        'Hiding this tile will reset the "hide on" property on all of its content blocks.<br/><br/>Do you want to continue?',
      'edit-row-locked-content-error-message':
        'This tile contains locked block and therefore cannot be edited'
    },
    'mailup-common-patch-provider': {
      'title-14': 'Tile'
    },
    'mailup-common-row-display-condition-filter': {
      label: 'Select tile display conditions'
    },
    'mailup-bee-common-widgets-hide-on': {
      'module-hidden-as-row':
        'The property of this block is being overridden by its tile',
      'module-hidden-as-row-mobile':
        'The property of this block is being overridden by its tile in the mobile view',
      'module-hidden-as-row-desktop':
        'The property of this block is being overridden by its tile in the desktop view'
    },
    'mailup-bee-common-widgets-form': {
      'field-rows': 'Tile height'
    },
    'mailup-bee-newsletter-collaboration': {
      'locked-row-warning-message':
        'This tile is being edited by another user.',
      'locked-row-warning-title': 'This tile is locked',
      'row-delete-confirm-title': 'Are you sure you want to delete this tile?',
      'row-delete-confirm-message':
        'Another user is currently editing a module inside this tile. By deleting it, you might cause their work to be lost.',
      'delete-rejected-message': 'Another user is working in the same tile.',
      'hide-row-rejected-message': 'Another user is working in the same tile'
    },
    'mailup-bee-rows-management': {
      confirm: 'Do you want to delete the tile?'
    },
    'mailup-bee-common-widgets-background-video': {
      label: 'Tile background video'
    },
    'mailup-common-synced-row-stage-label': 'Synced Tile',
    'mailup-common-synced-row-saved': { label: 'Tile saved (synced)' },
    'mailup-common-synced-row-edited': { label: 'Tile un-synced' },
    'mailup-common-text-editor-provider': {
      'merge-tags-button-text': 'Personalized fields',
      'merge-tags-modal-title': 'Insert personalized fields',
      'special-link-default-text': 'Special links',
      'special-link-title': 'Special links'
    },
    'bee-common-top-bar': {
      'show-mergetags-preview': 'Show Personalization Fields Preview',
      'hide-mergetags-preview': 'Hide Personalization Fields Preview'
    },
    'mailup-common-href': {
      'link-file': 'Link to file'
    },
    'mailup-common-href': {
      'link-file': 'Link to file'
    },
    'mailup-bee-common-widgets-image-selector': {
      label: constants.featureFlags.EMAIL_UPLOAD_IMAGE_ENABLED
        ? 'Change image'
        : 'Specify image'
    },
    'mailup-bee-newsletter-modules-carousel': {
      'change-image': constants.featureFlags.EMAIL_UPLOAD_IMAGE_ENABLED
        ? 'Change image'
        : 'Specify image'
    },
    'mailup-bee-newsletter-modules-image': {
      browse: constants.featureFlags.EMAIL_UPLOAD_IMAGE_ENABLED
        ? 'Browse'
        : 'Specify image'
    }
  }
  // onSave:(jsonFile, htmlFile) => console.log('---onSave---', jsonFile, htmlFile, '---onSave---'),
  // onSend: htmlFile => console.log('---onSend---', htmlFile, '---onSend---'),
  // onSaveAsTemplate: jsonFile => console.log('---onSaveAsTemplate---', jsonFile, '---onSaveAsTemplate---'),
  // onError: errorMessage => console.log(errorMessage)
};

let isProcessing = 'none';
let changesDescription = '';
let formTrigger = () => {};
let postValidationFunc = () => {};

const BeeEmailEditor = ({
  flyoutOpen,
  className,
  config: beeConfig,
  onSave,
  onSaveAsTemplate,
  onSend,
  onStart,
  onLoad,
  defaultColors,
  onAutoSave,
  hideHeader,
  template,
  saveRows = false,
  onSaveRow: onSaveRowfn,
  onDeleteRow,
  emailBriefing,
  preview,
  onChangeHandler,
  onChange,
  onCustomRowBrand,
  onFilePicker: onFilePickerfn,
  onDisplayCondition,
  onABTestVariantValidation,
  ...props
}) => {
  const [beeEditor] = useState(new BeePlugin());
  const [isBeeLoading, setIsBeeLoading] = useState(true);
  // const beeEditor = new BeePlugin();
  const [autoSaving, setAutoSaving] = useState(0);
  const [openFlyout, setOpenFlyout] = useState(flyoutOpen);
  const [
    isHtmlChangesDescriptionModalOpen,
    setIsHtmlChangesDescriptionModalOpen
  ] = useState(false);
  const saveSubjectAndPreheaderRef = useRef('menu');
  const subjectPreheadeFormRef = useRef();
  const [validationDialog, setValidationDialog] = useState(false);
  const [validationError, setValidationError] = useState();
  const [showValidationAnimation, setShowValidationAnimation] = useState(false);

  const brand = Array.isArray(emailBriefing.brand)
    ? 'brand[0].code'
    : 'brand.code';
  const campaignBrand = _.get(emailBriefing, brand, 1);
  const campaignBrandObj = _.get(emailBriefing, 'brand', {});
  const campaignCountryObj = _.get(emailBriefing, 'country', {});
  const campaignIsCrossBrand = _.get(emailBriefing, 'isCrossBrand', false);
  const campaignCountryLabel = _.get(emailBriefing, 'country.name', 1);
  const emailAttributes = _.get(emailBriefing, 'emailAttributes', {});
  const status = _.get(emailBriefing, 'status', '');
  const langCode = getLanguageCode(emailBriefing);
  // const languageLabel = _.get(emailBriefing, 'language.name', "");
  const { isLoading, setIsLoading } = props;
  const { authorizedUserData: user } = useSelector(state => state.authorized);
  const adminConfig = {};
  const contentDialogOptional = {};
  const [isOpen, setIsOpen] = useState(false);
  const [isABTestingGuidelineOpen, setIsABTestingGuidelineOpen] = useState(
    false
  );
  const [isEmailCouponGuidelineOpen, setIsEmailCouponGuidelineOpen] = useState(
    false
  );
  const [templateTestStatus, setTemplateTestStatus] = useState(
    constants.EMAIL_GUIDELINES_STATUS.INITIALIZE
  );
  const [currTemplate, setCurrTemplate] = useState(template);
  const [isApplyStylesModalOpen, setIsApplyStylesModalOpen] = useState(false);
  const [brandStylesApplied, setBrandStylesApplied] = useState(false);
  const runTestRef = useRef();
  const runTestCount = useRef(0);

  // useEffect(() => {
  //   setBrandStylesApplied(!!localStorage.getItem(`styles-${campaignBrand}`))
  // }, [])

  const onClose = () => {
    setIsOpen(false);
  };
  const handleABTestingGuidelineClose = () => {
    setIsABTestingGuidelineOpen(false);
  };
  const handleEmailCouponGuidelineClose = () => {
    setIsEmailCouponGuidelineOpen(false);
  };
  const handleApplyStylesModalClose = () => {
    setIsApplyStylesModalOpen(false);
  };
  const isEmailAdminOrACS = checkIsEmailAdminOrACS(user);
  const isUserRequestorOrApprover = checkIfUserIsRequestorOrApprover(user);
  const showABTestCondition = hasEmailContentABTest(emailBriefing);
  const beeRoles = {
    roleHash: 'restrictDisplayConditions'
  };

  if (isEmailAdminOrACS || isUserRequestorOrApprover) {
    adminConfig.onDeleteRow = {
      handler: function(resolve, reject, args) {
        onDeleteRow(args, error => {
          if (error) {
            return reject(error);
          } else {
            resolve(true);
          }
        });
      }
    };
  }

  if (!constants.featureFlags.EMAIL_UPLOAD_IMAGE_ENABLED) {
    contentDialogOptional.filePicker = {
      handler: function(resolve, reject, args) {
        onFilePickerfn(args, (error, result) => {
          if (error) {
            reject(error);
          } else {
            resolve({ url: result });
          }
        });
      }
    };
  }

  if (showABTestCondition) {
    contentDialogOptional.rowDisplayConditions = {
      label: 'Configure A/B test conditions',
      handler: function(resolve, reject, args) {
        onDisplayCondition(args, (error, result) => {
          if (error) {
            reject(error);
          } else {
            resolve(result);
          }
        });
      }
    };

    beeRoles.roleHash = 'canRemoveDisplayConditions';
  }

  const beeRowsConfig = {
    rowsConfiguration: {
      emptyRows: true,
      defaultRows: !constants.featureFlags
        .ENABLE_HIDE_EMAIL_EDITOR_DEFAULT_ROWS,
      externalContentURLs: getFilterRows(
        campaignBrandObj,
        campaignCountryObj,
        'simple',
        onCustomRowBrand,
        campaignIsCrossBrand
      )
    }
  };

  DEFAULT_CONFIGURATION.contentDefaults = {
    button: {
      label: 'Button',
      styles: {
        backgroundColor: '#3AAEE0'
      }
    }
  };

  if (!saveRows) {
    beeRowsConfig.saveRows = saveRows;
  }

  const filteredMergeTags = [...constants.BEE_EDITOR_SETUP.mergeTags];
  const dynamicCouponTagIndex = filteredMergeTags.findIndex(
    tag => tag.name === 'Dynamic coupon code'
  );

  if (
    emailAttributes?.emailCoupon?.type !== 'dynamicCoupon' &&
    dynamicCouponTagIndex > -1
  ) {
    filteredMergeTags.splice(dynamicCouponTagIndex, 1);
  }

  const beeFinalConfig = {
    ...{
      ...DEFAULT_CONFIGURATION,
      mergeTags: filteredMergeTags
    },
    ...beeConfig,
    ...beeRowsConfig,
    ...beeRoles,
    ...getRTLConfig(langCode),
    ...getMetaDataLanguageConfig(props.languageList),
    onStart,
    onAutoSave,
    contentDialog: {
      saveRow: {
        handler: function(resolve, reject, args) {
          onSaveRowfn(args, (error, result) => {
            if (error) {
              reject(error);
            } else {
              resolve(result);
            }
          });
        }
      },
      externalContentURLs: getFilterRows(
        campaignBrandObj,
        campaignCountryObj,
        '',
        onCustomRowBrand,
        campaignIsCrossBrand
      ),
      ...contentDialogOptional,
      ...adminConfig
    },
    advancedPermissions: {},
    onSaveRow: (json, htmlPrev, partialPage) => {
      const parsedJson = JSON.parse(json);
      // update the email block with html.
      const _id = parsedJson.metadata._id;
      const payload = {
        templateHtml: htmlPrev,
        templateObject: parsedJson
      };
      if (_id) {
        updateEmailRowBlock(_id, payload)
          .then(result => {
            if (result.status) {
              // we are good to go
            } else {
              // TODO: maybe show a toast mesg for api fail
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    onChange: (jsonFile, response) => {
      isProcessing = 'none';
      setAutoSaving(1);
      const { modifiedJson, hasRemovedDisplayCondition } = onChange(jsonFile);
      const saveRes = onSave(JSON.stringify(modifiedJson));
      setCurrTemplate(modifiedJson);
      saveRes.then(res => {
        res.status === 'saved' ? setAutoSaving(0) : setAutoSaving(2);
        if (hasRemovedDisplayCondition) {
          // reload the template.
          beeEditor.reload(modifiedJson);
        }
      });
    },
    onSaveAsTemplate: jsonFile => {
      onSaveAsTemplate(jsonFile);
    },
    onSave: (jsonFile, htmlFile) => {
      setAutoSaving(0);
      const invokeCallback =
        isProcessing === constants.ACTION_TYPES.SEND_EMAIL
          ? beeEditor.send
          : isProcessing === constants.ACTION_TYPES.TRIGGER_VALIDATION
          ? openValidationDialog
          : isProcessing === constants.ACTION_TYPES.TRIGGER_VALIDATION_ASYNC
          ? postValidationFunc 
          : undefined;
      onSave(jsonFile, htmlFile, {
        isProcessing,
        changesDescription,
        callBack: invokeCallback
      });
      if (
        isProcessing === constants.ACTION_TYPES.NEXT ||
        isProcessing === constants.ACTION_TYPES.BACK ||
        isProcessing ===
          constants.ACTION_TYPES.HTML_CHANGES_DESCRIPTION_SUBMIT ||
        isProcessing === constants.ACTION_TYPES.SEND_EMAIL ||
        isProcessing === constants.ACTION_TYPES.TRIGGER_VALIDATION
      ) {
        isProcessing = 'none';
      }
    },
    onSend: function(htmlFile) {
      onSend(htmlFile);
    },
    onLoad: () => {
      preview && beeEditor.preview();
      setIsBeeLoading(false);
      onLoad && onLoad();
    },
    onError: errorMessage => {
      console.log('BEE editor onError ::', errorMessage);
    },
    onWarning: alertMessage => {
      console.log('BEE editor onWarning ::', alertMessage);
    }
  };
  const onFetchBeeToken = (clientId, clientSecret, beeEditor) => {
    try {
      const beeEditorPromise = beeEditor.getToken(clientId, clientSecret);
      return beeEditorPromise;
    } catch (err) {
      return Promise.reject(new Error('Bee Token Error'));
    }
  };

  const applyBrandStyles = () => {
    if (props.brandDefaults.data) {
      // localStorage.setItem(`styles-${emailBriefing?.uid}`, JSON.stringify(props.brandDefaults.data))
      const newTemplate = applyDefaultStyles(
        props.brandDefaults.data,
        currTemplate,
        campaignBrand
      );
      isProcessing = 'applyingBrandStyles';
      beeEditor.reload(JSON.stringify(newTemplate));

      // setTimeout(()=> {
      //   setBrandStylesApplied(true)
      // }, 500)
      setTimeout(() => {
        beeEditor.save();
      }, 2000);
    }
  };

  const resetBrandStyles = () => {
    // const styles = localStorage.getItem(`styles-${campaignBrand}`)
    let styles = false; // temp to fix build. restore above line when adding reset functionality
    if (styles) {
      const newTemplate = applyDefaultStyles(
        JSON.parse(styles),
        currTemplate,
        campaignBrand
      );
      isProcessing = 'applyingBrandStyles';
      beeEditor.reload(JSON.stringify(newTemplate));

      // setTimeout(()=> {
      //   setBrandStylesApplied(false)
      // }, 500)

      setTimeout(() => {
        beeEditor.save();
      }, 2000);
    }
  };

  // useEffect(() => {
  //   localStorage.removeItem('styles')
  // }, [])

  useEffect(() => {
    if (campaignBrand !== 1) {
      onFetchBeeToken(
        constants.BEE_PLUGIN_CLIENT_ID,
        constants.BEE_PLUGIN_CLIENT_SECRET,
        beeEditor
      )
        .then(() => {
          console.log('Start BEE>>>', campaignBrand, beeFinalConfig);
          return beeEditor.start(beeFinalConfig, template);
        })
        .then(() => {
          console.log('>>>>>>>>>>> after editor init ::', beeEditor);
        })
        .catch(err => {
          console.log('Bee Error: ', err.message);
        });
    }
  }, [campaignBrand, defaultColors]);
  /**
   * ActionBar handlers to save as template
   */
  const saveAsTemplate = () => {
    isProcessing = 'saveAsTemplate';
    beeEditor.save();
  };
  /**
   * ActionBar handlers to save
   */
  const saveTemplate = () => {
    isProcessing = 'none';
    beeEditor.save();
  };
  /**
   * ActionBar handlers to send test emails
   */
  const sendTemplate = () => {
    isProcessing = 'sendEmail';
    beeEditor.save();
    //beeEditor.send();
  };

  /**
   * ActionBar handlers to send test emails
   */
  const downloadTemplateImg = () => {
    isProcessing = 'downloadTemplateImg';
    beeEditor.save();
    //beeEditor.send();
  };

  /**
   * ActionBar handlers to run template validation
   */
  const triggerValidation = () => {
    isProcessing = 'triggerValidation';
    beeEditor.save();
    // if(!validationError && isEmpty(validationError)){
    //   isProcessing = 'triggerValidation';
    //   beeEditor.save();
    // } else {
    //   setValidationDialog(true);
    // }
  };

  const openValidationDialog = () => {
    resetValidationDialog();
    setValidationDialog(true);
  };
  const resetValidationDialog = () => {
    setTemplateTestStatus(constants.EMAIL_GUIDELINES_STATUS.INITIALIZE)
    setValidationDialog(false);
  };

  const runTests = () => {
    return axios
      .get(
        `${constants.serviceUrls.EMAIL_TEMPLATE_VALIDATION}/${uid}/validateTemplate`,
        {}
      )
      .then(function(res) {
        return res.data;
      })
      .catch(function(err) {
        return err;
      });
  };
  /**
   * Toolbar next button handler
   * @param {*} event
   */
  const handleNext = (
    temp,
    { htmlChangesDescriptionSubmission = false, isLoaderEnabled = false } = {}
  ) => {
    if (isSubjectAndPreheaderNotPresent(emailBriefing)) {
      setOpenFlyout(true);
      setTimeout(() => {
        formTrigger();
      }, 500);
      setIsLoading(false);
      return null;
    }

    // if (showABTestCondition && onABTestVariantValidation()) {
    //   if (isLoaderEnabled) {
    //     setIsLoading(false);
    //   }
    //   return null;
    // }
    isProcessing = 'triggerValidationAsync';
    setShowValidationAnimation(true);
    beeEditor.save();
    postValidationFunc = () => {
      runTestRef
      .current()
      .then(res => {
        setShowValidationAnimation(false);
        isProcessing = 'none';
        if (!res) {
          if (htmlChangesDescriptionSubmission) {
            setIsHtmlChangesDescriptionModalOpen(true);
            isProcessing =
              constants.ACTION_TYPES.HTML_CHANGES_DESCRIPTION_SUBMIT;
          } else {
            isProcessing = 'next';
          }
          localStorage.removeItem(`styles-${campaignBrand}`);
          beeEditor.save();
        } else {
          return false;
        }
      })
      .catch(error => {
        setShowValidationAnimation(false);
        console.log('error: ', error);
      });
    }
  };
  /**
   * Toolbar back button handler
   * @param {*} event
   */
  const handleBack = event => {
    isProcessing = 'back';
    // localStorage.removeItem(`styles-${campaignBrand}`)
    beeEditor.save();
  };

  const handleChangeTemplate = () => {
    isProcessing = constants.ACTION_TYPES.CHANGE_TEMPLATE;
    beeEditor.save();
  };

  const handleSubmit = () => {
    setIsLoading(true);
    handleNext(null, {
      htmlChangesDescriptionSubmission: true,
      isLoaderEnabled: true
    });
  };

  const { history, uid } = props;
  const hanldeHtmlChangesDescriptionModalSubmission = async data => {
    if (data) {
      const { changesDescription: value } = data;
      changesDescription = value;
      await updateChangesDescription(uid, { changesDescription });
      history.push('/campaign-wizard/emailbriefing/' + uid + '/execution');
    }
  };

  const isAcsOrAdminUserAfterSubmission =
    (status === constants.EMAIL_STATUS.AWAITING_SETUP ||
      status === constants.EMAIL_STATUS.AWAITING_INFO) &&
    isEmailAdminOrACS;

  return (
    <>
      {isBeeLoading ? (
        <Loader style={{ background: 'white', zIndex: 1000 }} />
      ) : null}
      <div>
        <HTMLChangesDescription
          isOpen={!isLoading && isHtmlChangesDescriptionModalOpen}
          onClose={() => {
            setIsHtmlChangesDescriptionModalOpen(false);
          }}
          onSubmit={hanldeHtmlChangesDescriptionModalSubmission}
        />
        {!hideHeader ? (
          <div className="toolbar">
            <div className="toolbar__left d-flex">
              <a
                href="/campaign-wizard"
                className={'logo-link'}
                title="Campaign Wizard"
              >
                <img src={logo} alt="Campaign Wizard logo" className={'logo'} />
              </a>
              {isAcsOrAdminUserAfterSubmission ? null : (
                <button
                  className="btn btn btn-outline-secondary mr-3 toolbar__nav-button"
                  type="button"
                  onClick={handleBack}
                >
                  Back
                </button>
              )}
              <ReactTooltip
                id={'editorLayout'}
                place="bottom"
                type="info"
                multiline={true}
                className="cw-tooltip cw-email-tooltip saas_new_master_tooltip"
              />
              <Action
                icon={gridIcon}
                title="Show layout"
                id="show-layout"
                onClick={() => beeEditor.toggleStructure()}
              />
              <Action
                icon={ActionEmailChecklistIcon}
                title="Design checklist"
                id="design-checklist"
                onClick={() => setIsOpen(true)}
              />
              {showABTestCondition && (
                <Action
                  icon={ABTestGuidelineIcon}
                  title="AB test guidelines"
                  id="ab-test-guidelines"
                  onClick={() => {
                    setIsABTestingGuidelineOpen(true);
                  }}
                />
              )}
              {emailAttributes?.emailCoupon?.couponApplied && (
                <Action
                  icon={EmailCouponIcon}
                  title="Coupon guidelines"
                  id="email-coupon-guidelines"
                  onClick={() => {
                    setIsEmailCouponGuidelineOpen(true);
                  }}
                />
              )}
              <FlyoutModal
                isOpen={openFlyout}
                onOpen={() => setOpenFlyout(true)}
                onClose={() => setOpenFlyout(false)}
                btnClass="action-bar__action btn checklist-btn"
                btnStyle={{ width: 'unset', padding: 3, paddingLeft: 4 }}
                buttonContent={
                  <span>
                    <i className="fas fa-pen icon-color-primary mr-2" />
                    Enter subject &amp; preheader
                  </span>
                }
                modelTitle={'Enter subject & preheader'}
                ref={saveSubjectAndPreheaderRef}
                externalSubmit={() => (
                  <button
                    className="btn btn-primary btn-block mt-10"
                    onClick={() => formTrigger()}
                    type="button"
                  >
                    Save
                  </button>
                )}
              >
                <SaveSubjectAndPreheader
                  isInFlyout={true}
                  emailBriefing={emailBriefing}
                  onClose={() => {
                    setOpenFlyout(false);
                    if (props.refetchEmailBriefing) {
                      props.refetchEmailBriefing();
                    }
                  }}
                  authorizedUserData={props.authorizedUserData}
                  formTriggerCallback={trigger => {
                    formTrigger = trigger;
                  }}
                />
              </FlyoutModal>
              {
                <Action
                  icon={ApplyBrandStylesIcon}
                  title="Apply brand styling"
                  id="styles"
                  onClick={() => setIsApplyStylesModalOpen(true)}
                />
              }
              {!isAcsOrAdminUserAfterSubmission && (
                <Action
                  icon={ChangeTemplateIcon}
                  title="Change template"
                  id="change-template"
                  onClick={handleChangeTemplate}
                />
              )}
            </div>
            <div className="toolbar__center">
              <div className="col">
                <span className="toolbar__nav-saving-status">
                  {autoSaving === 0
                    ? 'Saved'
                    : autoSaving === 1
                    ? 'Saving..'
                    : autoSaving === 2
                    ? 'Error'
                    : ''}
                </span>
              </div>
            </div>
            <div className="toolbar__right">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <ActionBar
                    onMobilePreview={() => {
                      beeEditor.preview();
                    }}
                    onDesktopPreview={() => beeEditor.preview()}
                    isExportButton={false}
                    isSaveButton={true}
                    isPreviewMobile={false}
                    isRunTests={true}
                    onRunTests={runTests}
                    templateTestStatus={templateTestStatus}
                    setTemplateTestStatus={setTemplateTestStatus}
                    showTemplateTest={true}
                    onSaveClick={saveTemplate}
                    onSaveAsClick={saveAsTemplate}
                    sentTestEmailOnClick={sendTemplate}
                    hideDesignCheckListBtn={true}
                    downloadTemplateImg={downloadTemplateImg}
                    isDownloadBtn={true}
                    validationClickHandler={triggerValidation}
                    shouldOpenValidationDialog={validationDialog}
                    setOpenValidationDialog={setValidationDialog}
                    validationError={validationError}
                    setValidationError={setValidationError}
                    resetValidationDialog={resetValidationDialog}
                    runTestRef={runTestRef}
                    runTestCount={runTestCount}
                    showValidationAnimation={showValidationAnimation}
                    setShowValidationAnimation={setShowValidationAnimation}
                  />
                </div>
                <div className="ml-2">
                  <button
                    className="btn btn-primary toolbar__nav-button"
                    type="button"
                    onClick={
                      isAcsOrAdminUserAfterSubmission
                        ? handleSubmit
                        : handleNext
                    }
                  >
                    {isAcsOrAdminUserAfterSubmission ? 'Submit' : 'Next'}
                  </button>
                </div>
              </div>
            </div>
            {/* <button
            className="btn btn-primary mb-20 mt-15 ml-10"
            onClick={saveAstemplate}
          >
            {' '}
            Save as template
          </button> */}
          </div>
        ) : null}
      </div>
      <div
        id="bee-plugin-container"
        className={classNames('bee-editor-container', className)}
      ></div>
      <EmailDesignChecklistModal isOpen={isOpen} onClose={onClose} />
      <EmailCouponGuidelines
        isOpen={isEmailCouponGuidelineOpen}
        handleCloseForm={handleEmailCouponGuidelineClose}
        defaultTab={
          emailAttributes?.emailCoupon?.type === 'dynamicCoupon' ? 1 : 0
        }
      />
      <EmailABTestingGuidelines
        isOpen={isABTestingGuidelineOpen}
        handleCloseForm={handleABTestingGuidelineClose}
      />
      <ApplyBrandStyleModal
        isOpen={isApplyStylesModalOpen}
        brandStylesExist={props.brandDefaults.data}
        brandStylesApplied={brandStylesApplied}
        onClose={handleApplyStylesModalClose}
        applyBrandStyles={applyBrandStyles}
        resetBrandStyles={resetBrandStyles}
      />
    </>
  );
};

BeeEmailEditor.propTypes = {
  className: PropTypes.string,
  config: PropTypes.object,
  onSave: PropTypes.func,
  onSaveAsTemplate: PropTypes.func,
  onSend: PropTypes.func,
  onStart: PropTypes.func,
  onAutoSave: PropTypes.func,
  template: PropTypes.object.isRequired,
  emailBriefing: PropTypes.object
};

BeeEmailEditor.defaultProps = {
  onSave: (jsonFile, htmlFile, tempo) =>
    console.log('---onSave---', jsonFile, htmlFile, tempo, '---onSave---'),
  onSend: htmlFile => console.log('---onSend---', htmlFile, '---onSend---'),
  onSaveAsTemplate: jsonFile =>
    console.log('---onSaveAsTemplate---', jsonFile, '---onSaveAsTemplate---'),
  onError: errorMessage => console.log(errorMessage),
  onAutoSave: jsonFile => console.log('---onAutoSave---'),
  template: blankTemplate,
  config: {}
};

const mapStateToProps = function(state) {
  return {
    campaignConfig: state.app,
    emailBrandDetails: state.emailBrandDetails,
    brandDefaults: state.brandDefaults
  };
};

function Action({ id, icon, onClick, title }) {
  return (
    <>
      <ReactTooltip
        id={id}
        place="bottom"
        type="info"
        multiline={true}
        className="cw-tooltip cw-email-tooltip saas_new_master_tooltip"
      />
      <button
        onClick={onClick}
        className={'action-bar__action btn checklist-btn'}
        data-tip={title}
        data-for={id}
      >
        <img src={icon} alt={title} className={'action-bar-image'} />
      </button>
    </>
  );
}

export default connect(mapStateToProps, { getCampaignConfig })(BeeEmailEditor);
