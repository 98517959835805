import React, { useMemo } from 'react';
import Modal from 'react-modal';
import constants from 'constants/config';

const formatNames = (arr) => {
  const names = arr?.map(item => item?.name);
  if (names?.length === 0) return "";
  if (names?.length === 1) return names[0];
  if (names?.length === 2) return names.join(" and ");
  return `${names?.slice(0, -1).join(", ")}, and ${names[names.length - 1]}`;
};


export default function ApproversInfoBoxModal({
  isOpen,
  onClose,
  nameList,
  isApprovarAssignedToMarket,
  group,
  businessGroup = null
}) {

  const formattedNames = useMemo(() => formatNames(businessGroup), [businessGroup]);
  
  const busineGroupstr = businessGroup?.length > 1 ? 'business groups' :'business group'

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="cw-modal cw-modal--questions"
      contentLabel="Field Types"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <button className="cw-modal--close" title="Close" onClick={onClose}>
        <i className="fas fa-times"></i>
      </button>
      <h3 className="cw-heading--primary mb-10">Your approvers</h3>
      <div>
        <p>
          {isApprovarAssignedToMarket
            ? `Your brand is mapped to ${busineGroupstr} ${formattedNames}. Hence your campaign needs to be approved by one of the following approvers.`
            : `Your brand is mapped to ${busineGroupstr} ${formattedNames}. But your campaign needs to be approved by global leads as we do not have approvers on record for these ${busineGroupstr} in your market.`}
        </p>
        <br />
        <label>Approvers:</label>
        <p>{nameList}</p>
        <br />
        <p className="email-type-desc-font">
          The complete{' '}
          <a
            href={
              group === 'admin'
                ? constants.APPROVER_LIST_ADMIN_PAGE
                : constants.APPROVER_LIST_PAGE
            }
            target="_blank"
          >
            {` list of approvers `}
            <i className="fas fa-external-link-alt"></i>
          </a>{' '}
          for each country and business group is available for review. Please
          raise an{' '}
          <a href={constants.KANA_PAGE} target="_blank">
            {` UNA ticket `}
            <i className="fas fa-external-link-alt"></i>
          </a>{' '}
          to request any changes to your approvers list. Please note that any
          approver changes will be reflected on your future campaigns.
        </p>
      </div>
    </Modal>
  );
}
