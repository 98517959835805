import axios from 'axios';
import _, { orderBy } from 'lodash';
import constant from '../constants';
import { filterToQuery } from '../selectors';
const httpProtocolPrefix =
  process.env.NODE_ENV === 'development' ? 'http' : 'https';

export async function getCountries() {
  try {
    let response = await axios.get(constant.serviceUrls.SERVICE_URL.COUNTRIES);
    if (response) {
      let countryDetails = response.data;
      let countryDetailsSorted = orderBy(
        countryDetails.countries,
        ['name'],
        ['asc']
      );
      return countryDetailsSorted;
    }
    return [];
  } catch (e) {
    throw e;
  }
}

export async function getBrands() {
  const response = await axios.get(
    constant.serviceUrls.SERVICE_URL.BRANDS_LIST
  );

  const brandsList = response.data;
  const brandsListSorted = _.orderBy(brandsList.brands, ['name'], ['asc']);

  return brandsListSorted;
}

export async function getBrandByCountry(countryId) {
  try {
    let response = await axios.get(
      constant.serviceUrls.SERVICE_URL.COUNTRIES + '?country=' + countryId
    );

    if (response) {
      let countryBrand = response.data;
      let brands = countryBrand.map(data => data.brand);
      brands = orderBy(brands, ['name'], ['asc']);
      let languageExists =
        countryBrand[0].language !== undefined ? true : false;
      let countrylanguage;
      if (languageExists) {
        countrylanguage = {
          label: countryBrand[0]['language']['name'],
          value:
            countryBrand[0]['language']['code'] +
            '-' +
            countryBrand[0]['language']['_id']
        };
      }
      return { brands, countrylanguage };
    }
    return {};
  } catch (e) {
    throw e;
  }
}

export async function getBrandDetails() {
  try {
    let response = await axios.get(
      `${httpProtocolPrefix}:${constant.serviceUrls.SERVICE_URL.BRAND_DETAILS}`
    );
    if (response) {
      let brandDetails = response.data[0];

      return brandDetails;
    }
    return {};
  } catch (e) {
    throw e;
  }
}

export async function getEmailBriefingList(filters = {}) {
  const response = await axios.get(
    constant.serviceUrls.EMAIL_BRIEFINGS + filterToQuery(filters)
  );

  return response.data.data;
}

export async function createEmailBriefing(data) {
  try {
    const response = await axios.post(
      constant.serviceUrls.EMAIL_BRIEFINGS,
      data
    );
    if (response) {
      return response.data;
    }
    return {};
  } catch (e) {
    throw e;
  }
}

export async function getEmailBriefingbyUid(uid, options) {
  try {
    if (!uid) {
      throw new Error('uid is required!');
    }
    let response = await axios.get(
      `${constant.serviceUrls.EMAIL_BRIEFINGS}/${uid}` + filterToQuery(options)
    );
    if (response) {
      return response.data;
    }
    return {};
  } catch (e) {
    throw e;
  }
}

export async function deleteEmailBriefingbyUid(uid) {
  try {
    if (!uid) {
      throw new Error('uid is required!');
    }
    let response = await axios.get(
      `${constant.serviceUrls.EMAIL_BRIEFINGS}/${uid}/delete`
    );
    if (response) {
      return response.data;
    }
    return {};
  } catch (e) {
    throw e;
  }
}

export async function updateEmailBriefing(uid, data) {
  try {
    let response = await axios.post(
      `${constant.serviceUrls.EMAIL_BRIEFINGS}/${uid}`,
      data
    );
    return response.data;
  } catch (e) {
    throw e;
  }
}

export async function updateChangesDescription(uid, data) {
  try {
    let response = await axios.post(
      `${constant.serviceUrls.EMAIL_BRIEFINGS}/${uid}/update-changes-description`,
      data
    );
    return response.data;
  } catch (e) {
    throw e;
  }
}

export async function saveEmailRowBlock(data) {
  try {
    const response = await axios.post(
      constant.serviceUrls.EMAIL_BEE_GET_ROWS_URL,
      data
    );
    if (response) {
      console.log('response: ', response);
      return response.data;
    }
    return {};
  } catch (e) {
    console.log('saveEmailRowBlock e: ', e);
    throw e;
  }
}

export async function sendTestEmail(data) {
  try {
    const response = await axios.post(
      constant.serviceUrls.SEND_TEST_EMAIL_URL,
      data
    );
    if (response) {
      return response.data;
    }
    return {};
  } catch (e) {
    throw e;
  }
}

export async function updateEmailRowBlock(id, data) {
  try {
    const response = await axios.post(
      `${constant.serviceUrls.EMAIL_BEE_GET_ROWS_URL}/${id}/update`,
      data
    );
    if (response) {
      return response.data;
    }
    return {};
  } catch (e) {
    console.log('updateEmailRowBlock e: ', e);
    throw e;
  }
}

export async function getEmailRowCount(category, brand, country) {
  try {
    const response = await axios.get(
      `${constant.serviceUrls.EMAIL_BEE_GET_ROWS_URL}/count?category=${category}&brand=${brand}&country=${country}`
    );
    if (response) {
      return response.data;
    }
    return {};
  } catch (e) {
    console.log('getEmailRowCount e: ', e);
    throw e;
  }
}

export async function getEmailRowBlockById(id) {
  try {
    const response = await axios.get(
      `${constant.serviceUrls.EMAIL_BEE_GET_ROWS_URL}/${id}`
    );
    if (response) {
      return response.data;
    }
    return {};
  } catch (e) {
    console.log('getEmailRowBlockById e: ', e.message);
    throw e;
  }
}

export async function getTemplateCount(type, brand, country) {
  try {
    const response = await axios.get(
      `${constant.serviceUrls.EMAIL_CORE_TEMPLATES_URL}/count?type=${type}&brand=${brand}&country=${country}`
    );
    if (response) {
      return response.data;
    }
    return {};
  } catch (e) {
    console.log('getTemplateCount e: ', e);
    throw e;
  }
}

export async function getEmailBriefingCount(options) {
  try {
    const response = await axios.get(
      constant.serviceUrls.EMAIL_BRIEFINGS + '/count' + filterToQuery(options)
    );
    if (response) {
      return response.data;
    }
    return {};
  } catch (e) {
    console.log('getEmailBriefingCount e: ', e.message);
    throw e;
  }
}

export async function uploadCoupon(data) {
  try {
    const response = await axios.post(
      constant.serviceUrls.EMAIL_BRIEFINGS + '/couponupload',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    if (response) {
      return response.data;
    }
    return {};
  } catch (e) {
    console.log('getEmailBriefingCount e: ', e.message);
    throw e;
  }
}

export async function getBrandStyleConfigBrands() {
  const response = await axios.get(
    constant.serviceUrls.SERVICE_URL.GET_EMAIL_STYLE_CONFIG_BRANDS_LIST
  );

  const brandsList = response?.data;
  const brandsListSorted = _.orderBy(brandsList?.brands, ['name'], ['asc']);

  return brandsListSorted;
}
