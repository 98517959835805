import axios from 'axios';
import constants from '../constants';

export async function richTextInitLogger(data) {
  const response = await axios.post(
    `${constants.serviceUrls.LOGGER}/richtext`,
    data
  );
  return response.data;
}

export async function handleEditorInit(e) {
  const pageUrl = window.location.href;
  const segments = pageUrl.split('/');
  const isFormCampaignPage = pageUrl && pageUrl.match(/campaign\/([^/]+)\//);
  // const uid = segments.indexOf('campaign') ? segments[segments.indexOf('campaign') + 1] : '';
  // console.log('============', pageUrl.match(/campaign\/([^/]+)\//))
  const uid =
    isFormCampaignPage && isFormCampaignPage.length
      ? isFormCampaignPage[1]
      : '';
  const data = {
    event: 'EditorInit',
    targetId: e.target.id,
    pageUrl: pageUrl,
    pageuid: uid
  };
  const rteLogger = await richTextInitLogger(data);
}
